<template>
  <div class="layout-px-spacing">
    <teleport to="#breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">
                  <span>Сделки</span>
                </li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
    </teleport>

    <div class="panel-body simple-tab tabs">
      <a-radio-group v-if="initialDataFetched" class="my-3" button-style="solid" :value="filter.status" @update:value="setStatus">
        <a-radio-button value="IN">
          Входящие
        </a-radio-button>
        <a-radio-button value="CB">
          Перезвонить
        </a-radio-button>
        <a-radio-button value="QF">
          Квалификация
        </a-radio-button>
        <a-radio-button value="WK">
          В работу
        </a-radio-button>
        <a-radio-button value="NT">
          Нецелевые
        </a-radio-button>
        <a-radio-button value="CL">
          Отмена/отказ
        </a-radio-button>
      </a-radio-group>
      <div v-if="showFilter" class="panel br-6 mb-3">
        <DealFilter v-model="filter" @filter-changed="applyFilter" />
      </div>
      <a-spin v-if="!initialDataFetched" class="mt-3" size="large" />
      <div v-else class="panel br-6">
        <div class="custom-table">
          <div class="flex justify-end space-x-4" style="flex-wrap: wrap;">
            <router-link :to="routesEnum.dealAdd.path">
              <a-button class="btn btn-primary m-3">
                <PlusOutlined />
                Добавить
              </a-button>
            </router-link>

            <a-button class="btn btn-dark my-3 " @click="() => showFilter =! showFilter">
              Фильтр
            </a-button>
          </div>
          <a-table
            v-if="tableData"
            :locale="locale"
            :loading="tableDataLoading"
            :columns="columns"
            row-key="id"
            :data-source="tableData"
            :pagination="pagination"
            @change="changeHandler"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.dataIndex === 'id'">
                <router-link :to="routesEnum.dealPreview.path.replace(':id', record.id)">
                  {{ record.id }}
                </router-link>
                <br>
                <a-badge v-if="record.repeated" count="Повторное" :number-style="{ backgroundColor: 'orange' }" />
              </template>
              <template v-if="column.dataIndex === 'actions'">
                <div v-if="record.status !== 'В работу' && record.status !== 'Отмена/отказ'" class="mb-4 me-2 custom-dropdown dropdown btn-group">
                  <a id="pendingTask" class="btn dropdown-toggle btn-icon-only" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style="width: 24px; height: 24px"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-more-horizontal"
                    >
                      <circle cx="12" cy="12" r="1" />
                      <circle cx="19" cy="12" r="1" />
                      <circle cx="5" cy="12" r="1" />
                    </svg>
                  </a>

                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="pendingTask">
                    <li>
                      <router-link :to="routesEnum.dealEdit.path.replace(':id', record.id)" class="dropdown-item action-edit">
                        Редактировать
                      </router-link>
                    </li>
                    <li>
                      <router-link to="#" class="dropdown-item" @click="showTransferModal(record.id, record.creator)">
                        Передать
                      </router-link>
                    </li>
                    <li>
                      <router-link to="#" class="dropdown-item" @click="showStatusModal(record.id, record.status)">
                        Изменить статус
                      </router-link>
                    </li>
                    <li>
                      <a-popconfirm
                        title="Сделка будет отправлена в работу"
                        placement="left"
                        @confirm="sendToWork(record.id)"
                      >
                        <router-link to="#" class="dropdown-item">
                          Отправить в работу
                        </router-link>
                      </a-popconfirm>
                    </li>
                  </ul>
                </div>
              </template>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>

  <a-modal v-model:open="statusModalVisible" :confirm-loading="confirmStatusLoading" title="Изменин статус" @ok="saveStatus">
    <a-row :gutter="16">
      <a-col :md="24">
        <a-select
          v-model:value="statusForm.status"
          :options="statusOptions"
        />
      </a-col>
    </a-row>
  </a-modal>

  <a-modal v-model:open="transferModalVisible" :confirm-loading="confirmTransferLoading" title="Передать сделку" @ok="transferOrder">
    <a-row :gutter="16">
      <a-col :md="24">
        <a-select
          v-model:value="transferForm.creator"
          show-search
          placeholder="Менеджер отдела продаж"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="transferSalers.fetching ? undefined : null"
          :options="transferSalers.data"
          @search="getTransferSalers"
        >
          <template v-if="transferSalers.fetching" #notFoundContent>
            <a-spin size="small" />
          </template>
        </a-select>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script setup>
import { onMounted, ref, reactive, computed } from 'vue';
import '@/assets/sass/apps/invoice-list.scss';
import moment from 'moment';
import { debounce, isEmpty } from 'lodash';
import { RouterLink, useRoute, useRouter } from 'vue-router';
import { notification } from 'ant-design-vue';
import { PlusOutlined } from '@ant-design/icons-vue';
import { routesEnum } from '@/router/routes'
import { useMeta } from '@/composables/use-meta';
import * as api from '@/services/api';
import DealFilter from '@/views/deals/components/dealFilter.vue';
import useWebsocket from '@/composables/use-websocket';


useMeta({ title: 'Сделки' });

const route = useRoute();
const router = useRouter();
const columns = [
	{
		title: '#',
		dataIndex: 'id',
		key: 'id',
		sorter: true,
	},
	{
		title: 'Дата',
		dataIndex: 'createdDate',
		key: 'created_date',
		sorter: true,
	},
	{
		title: 'Номер телефона',
		dataIndex: 'phoneNumber',
		key: 'phoneNumber',
	},
	{
		title: 'Адрес',
		dataIndex: 'address',
		key: 'address',
	},
	{
		title: 'Город, с которого поступил звонок',
		dataIndex: 'callCity',
		key: 'callCity',
	},
	{
		title: 'Компания',
		dataIndex: 'company',
		key: 'company',
	},
	{
		title: 'Контрагент',
		dataIndex: 'kontragent',
		key: 'kontragent',
	},
	{
		title: 'Статус',
		dataIndex: 'status',
		key: 'status',
	},
	{
		title: 'Менеджер ОП',
		dataIndex: 'opmanager',
		key: 'opmanager',
	},
	{
		title: 'Менеджер КС',
		dataIndex: 'ksmanager',
		key: 'ksmanager',
	},
	{
		title: 'Действие',
		dataIndex: 'actions',
		key: 'actions',
	},
];

const showFilter = ref(false);

const transferSalers = reactive({
	data: [],
	fetching: false,
});
const statusForm = reactive({
	status: '',
});
const transferForm = reactive({
	creator: null,
});
const fullData = ref([]);
const initialDataFetched = ref(false);
const tableDataLoading = ref(false);
const statusModalVisible = ref(false);
const confirmStatusLoading = ref(false);
const transferModalVisible = ref(false);
const confirmTransferLoading = ref(false);
const editableOrderId = ref(null);
const locale = {
	emptyText: 'Сделок не найдено'
}
const filter = reactive({
	string: route.query.search || '',
	phoneNumber: route.query.phoneNumber || '',
	creator: route.query.creator || [],
	manager: route.query.manager || [],
	agent: route.query.agent || null,
	customer: route.query.customer || [],
	createdDateGte: route.query.createdDateGte ? 
		moment(new Date(filter.createdDateGte)).format('YYYY-MM-DD') : null,
	createdDateLte: route.query.createdDateLte ? 
		moment(new Date(filter.createdDateLte)).format('YYYY-MM-DD') : null,
	status: route.query.status || 'IN',
})
const pagination = computed(() => ({
	total: fullData.value.count,
	current: +route.query.page || 1,
	pageSize: 20,
	hideOnSinglePage: true,
}));

const tableData = computed(() =>
	(fullData?.value?.results || []).map(item => ({
		id: item?.id,
		repeated: item?.call?.repeated,
		createdDate: item?.createdDate,
		phoneNumber: item?.call?.phone || '-',
		address: item?.addressWithoutIndex,
		company: item?.agent?.name || '-',
		kontragent: item?.customer?.name || '-',
		status: item?.status,
		creator: item?.creator?.id,
		opmanager: item?.creator?.fullName || '-',
		ksmanager: item?.manager?.fullName || '-',
		callCity: item?.call?.subscriber?.sourceName || '-',
	}))
);
const statusOptions = ref([
	{ value: 'CB', label: 'Перезвонить' },
	{ value: 'QF', label: 'Квалификация' },
	{ value: 'NT', label: 'Нецелевые' },
	{ value: 'CL', label: 'Отмена/отказ' },
]);
const getTransferSalers = debounce(async value => {
	transferSalers.data = [];
	transferSalers.fetching = true;
	try {
		let { data } = await api.getUsers({search: value, saler: true});
		transferSalers.data = data.results.map(user => ({
			label: `${user.lastName} ${user.firstName} ${user.middleName}`,
			value: user.id,
		}));
	} catch(e) {
		console.log(e);
	} finally {
		transferSalers.fetching = false;
	}
}, 300);

const addParamsToLocation = params => {
	router.push({ query: params })
}

const getOrders = async (params) => {
	tableDataLoading.value = true;
	params['expand'] = 'agent,customer,creator,manager,call';
	params['pageSize'] = 20;
	try {
		const { data } = await api.getOrders(params);
		fullData.value = data;
		pagination.value.total = data.count;
	} catch(e) {
		console.log(e);
	} finally {
		setTimeout(() => tableDataLoading.value = false, 1000);
	}
}

const changeHandler = async (pag, _, sorter) => {
	pagination.value.current = pag.current;
	let params = {page: pag.current};
	if(sorter.columnKey && sorter.order) {
		let sign = '';
		if(sorter.order === 'descend') {
			sign = '-';
		}
		params['ordering'] = sign + sorter.columnKey;
	}
	if (filter.string) {
		params['search'] = filter.string;
	}
	if (filter.phoneNumber) {
		params['phone_number'] = filter.phoneNumber;
	}
	if (filter.status) {
		params['status'] = filter.status;
	}
	if (filter.createdDateGte) {
		params['created_date_gte'] = moment(new Date(filter.createdDateGte)).format('YYYY-MM-DD');
	}
	if (filter.createdDateLte) {
		params['created_date_lte'] = moment(new Date(filter.createdDateLte)).format('YYYY-MM-DD');
	}
	if (filter.creator) {
		params['creator'] = filter.creator;
	}
	if (filter.manager) {
		params['manager'] = filter.manager;
	}
	if (filter.agent) {
		params['agent'] = filter.agent;
	}
	if (filter.customer) {
		params['customer'] = filter.customer;
	}
	addParamsToLocation(params);
	getOrders(params);
}

const applyFilter = () => {
	pagination.value.current = 1;
	let params = { page: 1 }
	if(filter.string) {
		params['search'] = filter.string;
	}
	if (filter.phoneNumber) {
		params['phone_number'] = filter.phoneNumber;
	}
	if (filter.status) {
		params['status'] = filter.status;
	}
	if (filter.createdDateGte) {
		params['created_date_gte'] = moment(new Date(filter.createdDateGte)).format('YYYY-MM-DD');
	}
	if (filter.createdDateLte) {
		params['created_date_lte'] = moment(new Date(filter.createdDateLte)).format('YYYY-MM-DD');
	}
	if (filter.creator) {
		params['creator'] = filter.creator;
	}
	if (filter.manager) {
		params['manager'] = filter.manager;
	}
	if (filter.agent) {
		params['agent'] = filter.agent;
	}
	if (filter.customer) {
		params['customer'] = filter.customer;
	}
	addParamsToLocation(params);
	getOrders(params);
}

const setStatus = async (value) => {
	filter.status = value;
    
	pagination.value.current = 1;
	let params = { page: 1 }
	params['status'] = filter.status;
	if (filter.string) {
		params['search'] = filter.string;
	}
	if (filter.ordering) {
		params['ordering'] = filter.ordering;
	}

	addParamsToLocation(params);
	getOrders(params);
}

const showStatusModal = (orderId, status) => {
	editableOrderId.value = orderId;
	statusForm.status = status;
	statusModalVisible.value = true;
};

const showTransferModal = (orderId, creator) => {
	editableOrderId.value = orderId;
	transferForm.creator = creator;
	transferModalVisible.value = true;
	getTransferSalers(creator);
};

const saveStatus = async () => {
	confirmStatusLoading.value = true;
	try {
		const res = await api.setOrderStatus(editableOrderId.value, {status: statusForm.status});
		if (res.status === 200) {
			editableOrderId.value = null;
			statusModalVisible.value = false;
			confirmStatusLoading.value = false;
			applyFilter();
		}
	} catch(e) {
		confirmStatusLoading.value = false;
		notification.error({
			message: 'Ошибка',
			description: 'Не выбран доступный статус.'
		});
	}
};

const transferOrder = async () => {
	confirmTransferLoading.value = true;
	try {
		const res = await api.patchOrder(editableOrderId.value, {creator: transferForm.creator});
		if (res.status === 200) {
			editableOrderId.value = null;
			transferModalVisible.value = false;
			confirmTransferLoading.value = false;
			applyFilter();
		}
	} catch(e) {
		confirmTransferLoading.value = false;
		notification.error({
			message: 'Ошибка',
			description: e,
		});
	}
};

const sendToWork = async (orderId) => {
	try {
		const res = await api.sendOrderToWork(orderId);
		if (res.status === 200) applyFilter();
	} catch(e) {
		console.log(e);
	}
};

const wsConnect = () => {
	useWebsocket((data) => !isEmpty(data) && applyFilter());
};

onMounted(async () => {
	let params = {};
	params['page'] = pagination.value.current;
	params['status'] = filter.status;
	if (filter.string) {
		params['search'] = filter.string;
	}
	if (filter.phoneNumber) {
		params['phone_number'] = filter.phoneNumber;
	}
	if (filter.createdDateGte) {
		params['created_date_gte'] = moment(new Date(filter.createdDateGte)).format('YYYY-MM-DD');
	}
	if (filter.createdDateLte) {
		params['created_date_lte'] = moment(new Date(filter.createdDateLte)).format('YYYY-MM-DD');
	}
	if (filter.creator) {
		params['creator'] = filter.creator;
	}
	if (filter.manager) {
		params['manager'] = filter.manager;
	}
	if (filter.agent) {
		params['agent'] = filter.agent;
	}
	if (filter.customer) {
		params['customer'] = filter.customer;
	}
	await getOrders(params);
	initialDataFetched.value = true;

	wsConnect();
});
</script>

