<template>
  <a-config-provider
    :locale="ruRu"
    :theme="{
      token: {
        colorPrimary: '#4361ee',
      },
    }"
  >
    <div :class="[$store.state.layout_style, $store.state.menu_style]">
      <component :is="components[layout]" />
    </div>
  </a-config-provider>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import '@/assets/sass/app.scss';
import { useStore } from 'vuex';
import { useMeta } from '@/composables/use-meta';
import Cookies from 'js-cookie';
import ruRu from 'ant-design-vue/es/locale/ru_RU';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import appLayout from './layouts/app-layout.vue';
import authLayout from './layouts/auth-layout.vue';

dayjs.locale('ru');

useMeta({ title: 'Standart Express' });

const store = useStore();
const layout = computed(() => store.getters.layout);

const components = computed(() => ({
	app: appLayout,
	auth: authLayout,
}));

onMounted(() => {
	Cookies.set('sessionid', '');
});
</script>
