<template>
  <h5 v-if="showList">
    Контактные лица:
  </h5>
  <div
    v-if="showList && persons.length !== 0"
    id="contact_person"
    class="mt-2"
  >
    <div
      v-for="contactPerson in persons"
      id="contact_person_list"
      :key="contactPerson.id"
    >
      <div
        class="line row m-0"
        :class="{currentPerson: contactPerson.id === editableContactPerson}"
      >
        <div class="description col-8">
          {{ contactPerson.lastName }} {{ contactPerson.firstName }}
          {{ contactPerson.middleName }}
          <br>
          <a :href="'tel:' + contactPerson.phoneNumber">
            {{ contactPerson.phoneNumber }}
          </a>
          <br>
          <a
            :href="'mailto:' + contactPerson.email"
            target="_blank"
          >
            {{ contactPerson.email }}
          </a>
        </div>
        <div class="col">
          <a-popconfirm
            title="Контактное лицо будет удалено"
            popper-class="delete_contact_person_confirm"
            placement="left"
            @confirm="() => deleteContactPerson(contactPerson.id)"
          >
            <a-button class="btn btn-sm btn-danger">
              <DeleteOutlined />
            </a-button>
          </a-popconfirm>

          <a-button class="btn btn-sm btn-primary mx-2" @click="() => fillContactPersonForm(contactPerson.id)">
            <EditOutlined />
          </a-button>
        </div>
      </div>
      <hr>
    </div>
    <br>
  </div>
  <div
    v-show="visible || !showList"
    class="mb-4"
  >
    <a-form
      ref="formRef"
      class="mb-4"
      :model="form"
      :rules="rules"
    >
      <a-row :gutter="16">
        <a-col :span="8" :md="8" :xs="24">
          <a-form-item
            name="lastName"
            :validate-status="errors.lastName ? 'error' : null"
            :help="false"
            :extra="errors.lastName ? errors.lastName : ' '"
            :has-feedback="!!errors.lastName"
          >
            Фамилия
            <a-input
              v-model:value="form.lastName"
              type="text" placeholder="Фамилия"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8" :md="8" :xs="24">
          <a-form-item
            name="firstName"
            :validate-status="errors.firstName ? 'error' : null"
            :help="false"
            :extra="errors.firstName ? errors.firstName : ' '"
            :has-feedback="!!errors.firstName"
          >
            Имя*
            <a-input
              v-model:value="form.firstName"
              type="text" placeholder="Имя"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8" :md="8" :xs="24">
          <a-form-item
            name="middleName"
            :validate-status="errors.middleName ? 'error' : null"
            :help="false"
            :extra="errors.middleName ? errors.middleName : ' '"
            :has-feedback="!!errors.middleName"
          >
            Отчество
            <a-input
              v-model:value="form.middleName"
              type="text" placeholder="Отчество"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12" :md="12" :xs="24">
          <a-form-item
            name="email"
            :validate-status="errors.email ? 'error' : null"
            :help="false"
            :extra="errors.email ? errors.email : ' '"
            :has-feedback="!!errors.email"
          >
            Email*
            <a-input
              v-model:value="form.email"
              type="email" placeholder="Email"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12" :md="12" :xs="24">
          <a-form-item
            name="phoneNumber"
            :validate-status="errors.phoneNumber ? 'error' : null"
            :help="false"
            :extra="errors.phoneNumber ? errors.phoneNumber : ' '"
            :has-feedback="!!errors.phoneNumber"
          >
            Номер телефона*
            <a-input
              v-model:value="form.phoneNumber"
              type="text" placeholder="Номер телефона"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-button
      v-show="!editContactVisible"
      id="contact_person_form_add_button"
      class="project mt-2"
      size="mini"
      @click="validateForm()"
    >
      Добавить
    </a-button>
    <a-button
      v-show="visible && editContactVisible"
      id="contact_person_form_save_button"
      size="mini"
      class="project mx-2"
      style="margin-left: 0!important;"
      @click="editContactPerson()"
    >
      Сохранить изменения
    </a-button>
    <a-button
      v-show="visible && showList"
      id="contact_person_form_close_button"
      class="mx-2"
      size="mini"
      @click="closeContactPersonForm()"
    >
      Отмена
    </a-button>

    <a-alert v-if="contactPersonFormAlert" message="Ошибка! Проверьте форму." type="error" class="mt-3" show-icon />
  </div>

  <a-button
    v-show="!visible && !editContactVisible && showList"
    id="contact_person_add_button"
    size="mini"
    class="project"
    @click="visible = true"
  >
    Добавить контактное лицо
  </a-button>
</template>

<script setup>
import { ref, onMounted, reactive } from 'vue';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons-vue';
import * as api from '@/services/api';

const props = defineProps({
	agentId: {
		type: Number,
		required: false,
		default: -1,
	},
	showList: Boolean,
	agentIsNotCreated: Boolean,
});

const formRef = ref();

const emptyForm = {
	id: -1,
	agent: props.agentId ? props.agentId : -1,
	lastName: '',
	firstName: '',
	middleName: '',
	email: '',
	phoneNumber: '',
};
const contactPersonFormAlert = ref(false);
const form = reactive({ ...emptyForm });

const persons = ref([]);

const editContactVisible = ref(false);

const editableContactPerson = ref(-2);

const visible = ref(false);

const idForCreation = ref(-1);

const errors = reactive({});

const validatePhoneNumber = async (rule, value) => {
	let errorText;
	try {
		const res = await api.checkPhoneNumber({ phoneNumber: value });
		if (res.status === 200) {
			delete errors[rule.field];
			return Promise.resolve();
		}
	} catch (err) {
		const errData = err?.response?.data;
		if (rule.field in errData) {
			errorText = errData[rule.field].join(', ')
			errors[rule.field] = errData[rule.field].join(', ');
			return Promise.reject(errorText);
		}
	}
};

const validateRequired = (rule, value) => {
	let errorText;
	if (value === '' || value === null || value === undefined) {
		errorText = 'Поле обязательно для заполнения';
		errors[rule.field] = errorText;
		return Promise.reject(errorText);
	}
	delete errors[rule.field];
	return Promise.resolve();
}

const rules = {
	firstName: [
		{ validator: validateRequired, trigger: 'change' },
		{
			max: 16,
			message: 'Количество введенных символов не должно превышать 16',
			trigger: 'change',
		},
	],
	lastName: [
		{
			max: 16,
			message: 'Количество введенных символов не должно превышать 16',
			trigger: 'change',
		},
	],
	middleName: [
		{
			max: 16,
			message: 'Количество введенных символов не должно превышать 16',
			trigger: 'change',
		},
	],
	phoneNumber: [
		{
			validator: validatePhoneNumber,
			trigger: 'change',
		},
	],
	email: [
		{ validator: validateRequired, trigger: 'change' },
		{ type: 'email', message: 'Некорректный электронный адрес', trigger: 'change' },
	],
}

const validateForm = async () => {
	formRef.value.validate()
		.then(async () => {
			contactPersonFormAlert.value = false;
			idForCreation.value--;
			if (props.agentIsNotCreated) {
				persons.value.push({ ...form, id: idForCreation.value });
				closeContactPersonForm();
			} else {
				createContactPerson();
				visible.value = false;
			}
		}).catch(() => {
			contactPersonFormAlert.value = true;
		});
}

const createContactPerson = () => {
	form.agent = props.agentId;

	api.createContactPerson(form)
		.then((response) => {

			if (!props.showList) $emit('contact-person-created', response.data);
			persons.value.push(response.data);
		}).catch((error) => {
			console.log(error);
		}).finally(() => {
			closeContactPersonForm();
		});
}

const clearForm = () => {
	Object.assign(form, { ...emptyForm });
	formRef.value.clearValidate();
}

const editContactPerson = () => {
	formRef.value.validate().then(() => {
		contactPersonFormAlert.value = false;
		const person = persons.value.filter((item) => item.id === editableContactPerson.value)[0];
		if (props.agentIsNotCreated) {
			Object.assign(persons.value[persons.value.indexOf(person)], { ...form })
			closeContactPersonForm();
		} else {
			api.editContactPerson(editableContactPerson.value, form)
				.then((response) => {
					Object.assign(persons.value[persons.value.indexOf(person)], response.data)
				})
				.catch((error) => {
					console.log(error);
				}).finally(() => closeContactPersonForm());
		}
	}
	).catch(() => {
		contactPersonFormAlert.value = true;
	});
}

const closeContactPersonForm = () => {
	clearForm();
	visible.value = false;
	editableContactPerson.value = 0;
	editContactVisible.value = false;
}

const deleteContactPerson = (id) => {
	if (id === editableContactPerson.value) closeContactPersonForm();
	if (!props.agentIsNotCreated) {
		api.deleteContactPerson(id)
			.then(() => {
				persons.value = persons.value.filter((item) => item.id !== id);
			}).catch((error) => { console.log(error); })
			.finally(() => {
			});
	} else {
		persons.value = persons.value.filter((item) => item.id !== id);
	}
}

const fillContactPersonForm = (id) => {
	Object.assign(form, { ...persons.value.filter((item) => item.id === id)[0] });
	editableContactPerson.value = id;
	editContactVisible.value = true;
	visible.value = true;
}

const createContactPersons = (agentId) => {
	const promises = [];

	persons.value.filter((item) => item.id < 0).forEach((item) => {
		promises.push(
			new Promise((resolve) => {
				api.createContactPerson({ ...item, agent: agentId })
					.then((response) => {
						const index = persons.value.findIndex((person) => person.id === item.id);
						persons.value[index] = response.data;
					}).finally(() => (resolve()));
			}),
		);
	});
	return Promise.all(promises);
}

const getContactPersons = async () => {
	if (!props.agentIsNotCreated && props.agentId) {
		try {
			let { data } = await api.getContactPersons({ agent: props.agentId })
			persons.value = data.results;
		} catch (e) {
			console.log(e);
		}
	}
}

defineExpose({ createContactPersons });

onMounted(() => {
	getContactPersons();
});
</script>
