<template>
  <div class="layout-px-spacing">
    <teleport to="#breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">
                  <span>Заказчики</span>
                </li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
    </teleport>


    <!-- Входящие -->
    <div class="row layout-top-spacing">
      <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
        <div v-if="showFilter" class="panel br-6 mb-3">
          <AgentFilter v-model="filter" @filter-changed="applyFilter" />
        </div>
        <a-spin v-if="!initialDataFetched" size="large" />
        <div v-else class="panel br-6">
          <div class="custom-table">
            <div class="flex justify-end space-x-4" style="flex-wrap: wrap;">
              <router-link :to="routesEnum.clientAdd.path">
                <a-button class="btn m-3 btn-primary">
                  <PlusOutlined />
                  Добавить
                </a-button>
              </router-link>

              <a-button class="btn my-3 btn-dark" @click="() => showFilter =! showFilter">
                Фильтр
              </a-button>
            </div>
            <a-table
              v-if="tableData"
              :locale="locale"
              :loading="tableDataLoading"
              :columns="columns"
              row-key="id"
              :data-source="tableData"
              :pagination="pagination"
              @change="changeHandler"
            >
              <template #id="{ record }">
                <router-link :to="routesEnum.clientPreview.path.replace(':id', record.id)">
                  {{ record.id }}
                </router-link>
              </template>
              <template #actions="{ record }">
                <div class="mb-4 me-2 custom-dropdown dropdown btn-group">
                  <a id="pendingTask" class="btn dropdown-toggle btn-icon-only" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style="width: 24px; height: 24px"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-more-horizontal"
                    >
                      <circle cx="12" cy="12" r="1" />
                      <circle cx="19" cy="12" r="1" />
                      <circle cx="5" cy="12" r="1" />
                    </svg>
                  </a>

                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="pendingTask">
                    <li>
                      <router-link :to="routesEnum.clientEdit.path.replace(':id', record.id)" class="dropdown-item action-edit">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-edit-3"
                        >
                          <path d="M12 20h9" />
                          <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" />
                        </svg>
                        Редактировать
                      </router-link>
                    </li>
                  </ul>
                </div>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import { onMounted, ref, reactive, computed } from 'vue';
import '@/assets/sass/apps/invoice-list.scss';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons-vue';
import { RouterLink, useRoute, useRouter } from 'vue-router';
import { useMeta } from '@/composables/use-meta';
import { routesEnum } from '@/router/routes'
import * as api from '@/services/api';
import AgentFilter from '@/views/clients/components/agentFilter.vue';

useMeta({ title: 'Клиенты' });

const route = useRoute();
const router = useRouter();
const columns = [
	{
		title: 'Id клиента',
		dataIndex: 'id',
		key: 'id',
		sorter: true,
		slots: {
			customRender: 'id'
		}
	},
	{
		title: 'Дата',
		dataIndex: 'createdDate',
		key: 'created_date',
		sorter: true,
	},
	{
		title: 'Номер телефона',
		dataIndex: 'phoneNumber',
		key: 'phoneNumber',
	},
	{
		title: 'Город',
		dataIndex: 'city',
		key: 'city',
	},
	{
		title: 'Компания',
		dataIndex: 'company',
		key: 'company',
	},
	{
		title: 'Контрагент',
		dataIndex: 'kontragent',
		key: 'kontragent',
	},
	{
		title: 'Менеджер ОП',
		dataIndex: 'opmanager',
		key: 'opmanager',
	},
	{
		title: 'Менеджер КС',
		dataIndex: 'ksmanager',
		key: 'ksmanager',
	},
	{
		title: 'Действие',
		dataIndex: 'actions',
		key: 'actions',
		slots: {
			customRender: 'actions'
		}
	},
];
const showFilter = ref(false);

onMounted(async () => {
	let params = {};
	params['page'] = pagination.value.current;
	if (filter.string) {
		params['search'] = filter.string;
	}
	if (filter.phoneNumber) {
		params['any_phone'] = filter.phoneNumber;
	}
	if (filter.createdDateGte) {
		params['created_date_gte'] = moment(new Date(filter.createdDateGte)).format('YYYY-MM-DD');
	}
	if (filter.createdDateLte) {
		params['created_date_lte'] = moment(new Date(filter.createdDateLte)).format('YYYY-MM-DD');
	}
	if (filter.creator) {
		params['creator'] = filter.creator;
	}
	if (filter.manager) {
		params['manager'] = filter.manager;
	}
	if (filter.defaultAgent) {
		params['defaultAgent'] = filter.defaultAgent;
	}
	await getAgents(params);
	initialDataFetched.value = true;
});

const fullData = ref([]);
const initialDataFetched = ref(false);
const tableDataLoading = ref(false);
const locale = {
	emptyText: 'Заказчиков не найдено'
}
const filter = reactive({
	string: route.query.search || '',
	phoneNumber: route.query.phoneNumber || '',
	creator: route.query.creator || null,
	manager: route.query.manager || null,
	defaultAgent: route.query.creator || null,
	createdDateGte: route.query.createdDateGte ? 
		moment(new Date(filter.createdDateGte)).format('YYYY-MM-DD') : null,
	createdDateLte: route.query.createdDateLte ? 
		moment(new Date(filter.createdDateLte)).format('YYYY-MM-DD') : null,
})
const pagination = computed(() => ({
	total: fullData.value.count,
	current: +route.query.page || 1,
	pageSize: 20,
	hideOnSinglePage: true,
}));

const tableData = computed(() => {
	const results = fullData?.value?.results;
	if(!results) return [];
	const data = [];
	results.forEach(item => {
		data.push({
			id: item?.id,
			createdDate: item?.createdDate,
			phoneNumber: item?.number || '-',
			city: item?.city,
			company: item?.defaultAgent?.name || '-',
			kontragent: item?.name,
			opmanager: item?.creator?.fullName || '-',
			ksmanager: item?.manager?.fullName || '-',
		})
	})
	return data
})

const getAgents = async (params) => {
	tableDataLoading.value = true;
	params['agent_role'] = 'CUSTOMER';
	params['expand'] = 'defaultAgent,creator,manager';
	params['pageSize'] = 20;
	try {
		let { data } = await api.getAgents(params);
		fullData.value = data;
		pagination.value.total = data.count;
	} catch(e) {
		console.log(e);
	} finally {
		tableDataLoading.value = false;
	}
}

const addParamsToLocation = params => {
	router.push({ query: params })
}

const changeHandler = async (pag, _, sorter) => {
	pagination.value.current = pag.current;
	let params = {page: pag.current};
	if (filter.string) {
		params['search'] = filter.string;
	}
	if (filter.phoneNumber) {
		params['any_phone'] = filter.phoneNumber;
	}
	if (filter.createdDateGte) {
		params['created_date_gte'] = moment(new Date(filter.createdDateGte)).format('YYYY-MM-DD');
	}
	if (filter.createdDateLte) {
		params['created_date_lte'] = moment(new Date(filter.createdDateLte)).format('YYYY-MM-DD');
	}
	if (filter.creator) {
		params['creator'] = filter.creator;
	}
	if (filter.manager) {
		params['manager'] = filter.manager;
	}
	if (filter.defaultAgent) {
		params['default_agent'] = filter.defaultAgent;
	}
	if(sorter.columnKey && sorter.order) {
		let sign = '';
		if(sorter.order === 'descend') {
			sign = '-';
		}
		params['ordering'] = sign + sorter.columnKey;
	}
	addParamsToLocation(params);
	getAgents(params);
}

const applyFilter = () => {
	pagination.value.current = 1;
	let params = { page: 1 }
	if (filter.string) {
		params['search'] = filter.string;
	}
	if (filter.phoneNumber) {
		params['any_phone'] = filter.phoneNumber;
	}
	if (filter.createdDateGte) {
		params['created_date_gte'] = moment(new Date(filter.createdDateGte)).format('YYYY-MM-DD');
	}
	if (filter.createdDateLte) {
		params['created_date_lte'] = moment(new Date(filter.createdDateLte)).format('YYYY-MM-DD');
	}
	if (filter.creator) {
		params['creator'] = filter.creator;
	}
	if (filter.manager) {
		params['manager'] = filter.manager;
	}
	if (filter.defaultAgent) {
		params['default_agent'] = filter.defaultAgent;
	}
	addParamsToLocation(params);
	getAgents(params);
}
</script>