const USER_EMAIL = 'user_email'
const ACCESS_TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'
const USER_DATA_KEY = 'user_data'

const setToken = ({ user_email, access_token, refresh_token }) => {
	localStorage.setItem(USER_EMAIL, user_email);
	localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
	localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);
}

const setAccessToken = (token) => {
	localStorage.setItem(ACCESS_TOKEN_KEY, token);
}

const getAccessToken = () => {
	return localStorage.getItem(ACCESS_TOKEN_KEY);
}

const getRefreshToken = () => {
	return localStorage.getItem(REFRESH_TOKEN_KEY);
}

const getUserEmail = () => {
	return localStorage.getItem(USER_EMAIL);
}

const clearToken = () => {
	localStorage.removeItem(USER_EMAIL);
	localStorage.removeItem(ACCESS_TOKEN_KEY);
	localStorage.removeItem(REFRESH_TOKEN_KEY);
}

const setUserData = (data) => {
	localStorage.setItem(USER_DATA_KEY, JSON.stringify(data));
}

const getUserData = () => {
	const userData = localStorage.getItem(USER_DATA_KEY);
	return userData ? JSON.parse(userData) : {};
}

const clearUserData = () => {
	localStorage.removeItem(USER_DATA_KEY);
}

export {
	setToken,
	getAccessToken,
	getRefreshToken,
	getUserEmail,
	clearToken,
	setAccessToken,
	setUserData,
	getUserData,
	clearUserData,
}
