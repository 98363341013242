<template>
  <div>
    <!--  BEGIN NAVBAR  -->
    <div class="header-container fixed-top">
      <header class="header navbar navbar-expand-sm">
        <ul class="navbar-item theme-brand flex-row text-center">
          <li class="nav-item theme-logo">
            <router-link to="/">
              <img src="@/assets/images/logo.png" class="navbar-logo" alt="logo">
            </router-link>
          </li>
          <li class="nav-item theme-text">
            <router-link to="/" class="nav-link">
              Standart Express
            </router-link>
          </li>
        </ul>
        <div class="d-none horizontal-menu">
          <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-menu"
            >
              <line x1="3" y1="12" x2="21" y2="12" />
              <line x1="3" y1="6" x2="21" y2="6" />
              <line x1="3" y1="18" x2="21" y2="18" />
            </svg>
          </a>
        </div>
                
        <div class="navbar-item flex-row ms-md-auto">
          <div class="dropdown nav-item message-dropdown btn-group">
            <a id="ddlmsg" href="javascript:;" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only nav-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-mail"
              >
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                <polyline points="22,6 12,13 2,6" />
              </svg>
            </a>

            <ul
              v-if="!isEmpty(notifications)"
              class="dropdown-menu dropdown-menu-right"
              style="max-height: 280px; overflow-y: auto;"
              aria-labelledby="ddlmsg"
            >
              <li v-for="notification, index in notifications" :key="notification.id" role="presentation">
                <a role="menuitem" href="#" target="_self" class="dropdown-item" @click="moderateNewOrder(notification.id, notification.call.id)">
                  <div class="media media">
                    <div class="media-body">
                      <p>{{ notification.call.created_date }}</p>
                      <p class="mb-0">Готова заявка со входящего звонка с номера "{{ notification.call.phone }}"!</p>
                    </div>
                  </div>
                </a>
                <a-divider
                  v-if="index !== notifications.length - 1"
                  class="dropdown-menu__divider"
                />
              </li>
            </ul>

            <ul v-else class="dropdown-menu dropdown-menu-right" aria-labelledby="ddlmsg">
              <li role="presentation">
                <a role="menuitem" href="#" target="_self" class="dropdown-item">
                  <div class="media media">
                    <div class="media-body">
                      <p class="mb-0">Нет уведомлений</p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>

          <div class="dropdown nav-item user-profile-dropdown btn-group">
            <a id="ddlmsg" href="javascript:;" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only nav-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-user"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                <circle cx="12" cy="7" r="4" />
              </svg>
            </a>
            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddluser">
              <li role="presentation">
                <a href="" class="dropdown-item" @click="logout">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-log-out"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                    <polyline points="16 17 21 12 16 7" />
                    <line x1="21" y1="12" x2="9" y2="12" />
                  </svg>
                  Выйти
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </div>
    <!--  END NAVBAR  -->
    <!--  BEGIN NAVBAR  -->
    <div class="sub-header-container">
      <header class="header navbar navbar-expand-sm">
        <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-menu"
          >
            <line x1="3" y1="12" x2="21" y2="12" />
            <line x1="3" y1="6" x2="21" y2="6" />
            <line x1="3" y1="18" x2="21" y2="18" />
          </svg>
        </a>

        <!-- Portal vue/Teleport for Breadcrumb -->
        <div id="breadcrumb" class="vue-portal-target" />
      </header>
    </div>
    <!--  END NAVBAR  -->
  </div>
</template>


<script setup>
import { onMounted, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { isEmpty } from 'lodash-es';
import { userService } from '@/services/user'
import * as api from '@/services/api';
import { routesEnum } from '@/router/routes'
import useWebsocket from '@/composables/use-websocket';


const router = useRouter();
const newOrders = ref([]);

const notifications = computed(() => newOrders.value || []);

const logout = () => userService.logout();

const wsConnect = () => {
	useWebsocket((data) => {
		newOrders.value = data;
	});
};

const moderateNewOrder = async (id, callId) => {
	try {
		await api.moderateCall(callId)
		newOrders.value = newOrders.value.filter((item) => item.id !== id);
		await router.push(routesEnum.dealEdit.path.replace(':id', id));
	} catch (e) {
		console.log(e);
		return
	}
}

onMounted(() => {
	wsConnect();
});
</script>

<style scoped lang="scss">
.dropdown-menu {
    &__divider {
        margin-top: 5px;
        margin-bottom: 0px;
    }
}
</style>
