import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

const app = createApp(App);

import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import '@/assets/sass/components/custom-modal.scss';

import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

import { createHead } from '@vueuse/head';
const head = createHead();

import VueNouislider from 'vue3-nouislider';
import 'vue3-nouislider/dist/vue3-nouislider.css';

import { registerScrollSpy } from 'vue3-scroll-spy/dist/index';
registerScrollSpy(app, { offset: 118 });

import { ClientTable } from 'v-tables-3';

import VueFormWizard from 'vue3-form-wizard';
import 'vue3-form-wizard/dist/style.css';

import antd from './antd.config';
import 'ant-design-vue/dist/reset.css';

import appSetting from './app-setting';
window.$appSetting = appSetting;
window.$appSetting.init();

app
  .use(store)
  .use(router)
  .use(PerfectScrollbar)
  .use(VueNouislider)
  .use(ClientTable)
  .use(VueFormWizard)
  .use(head)
  .use(antd)
  .mount('#app');
