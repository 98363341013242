<template>
  <div class="layout-px-spacing dash_2">
    <teleport to="#breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active">
                  <a href="javascript:;">Дешборд</a>
                </li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav flex-row ms-auto">
        <li class="nav-item more-dropdown">
          <div class="dropdown custom-dropdown-icon">
            <a-button id="ddlSettings" class="btn btn-outline-info" aria-expanded="false" @click="() => showFilter =! showFilter">
              <FilterOutlined />
              <span>Фильтр</span>
            </a-button>
          </div>
        </li>
      </ul>
    </teleport>

    <div class="row layout-top-spacing">
      <div v-if="showFilter" class="col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="panel br-6">
          <DashboardFilter v-model="filter" @filter-changed="applyFilter" />
        </div>
      </div>
            
      <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-sales-category">
          <div class="widget-heading">
            <h5>Заявки</h5>
          </div>
          <div class="widget-content">
            <apex-chart v-if="sales_donut_options" height="320" type="donut" :options="sales_donut_options" :series="sales_donut_series" />
          </div>
        </div>
      </div>
            
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-sales-category">
          <div class="widget-heading">
            <h5>Обращения по времени</h5>
          </div>
          <div class="widget-content">
            <apex-chart v-if="sales_time_donut_options" height="320" type="donut" :options="sales_time_donut_options" :series="sales_time_donut_series" />
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-unique-visitors">
          <div class="widget-heading">
            <h5>Обращения</h5>
          </div>

          <div class="widget-content">
            <apex-chart v-if="unique_visitor_options" height="280" type="bar" :options="unique_visitor_options" :series="unique_visitor_series" />
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-total-balance">
          <div class="widget-content">
            <div class="account-box">
              <div class="info">
                <div class="inv-title">
                  <h5>Баланс по закрытым заявкам</h5>
                </div>
                <div class="inv-balance-info">
                  <p class="inv-balance">
                    <small>Выручка:</small> ₽ {{ earnings }}
                  </p>
                  <p class="inv-balance">
                    <small>Чистая прибыль:</small> ₽ {{ netProfit }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-visitor-by-browser">
          <div class="widget-heading">
            <h5>Обращения по источникам</h5>
          </div>
          <div v-if="sources_series.length > 0" class="widget-content">
            <div v-for="source, idx in sources_series" :key="idx" class="browser-list">
              <div class="w-icon icon-fill-warning">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-globe"
                >
                  <circle cx="12" cy="12" r="10" />
                  <line x1="2" y1="12" x2="22" y2="12" />
                  <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z" />
                </svg>
              </div>
              <div class="w-browser-details">
                <div class="w-browser-info">
                  <h6>{{ source.source }}</h6>
                  <p class="browser-count">
                    {{ source.percent }}% (итого: {{ source.total || 0 }})
                  </p>
                </div>
                <div class="w-browser-stats">
                  <div class="progress">
                    <div
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-valuenow="15"
                      class="progress-bar bg-gradient-warning"
                      :style="{ width: source.percent + '%' }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="widget-content">
            Данные отсутствуют
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import { onMounted, computed, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { FilterOutlined } from '@ant-design/icons-vue';
import ApexChart from 'vue3-apexcharts';
import moment from 'moment';
import { useMeta } from '@/composables/use-meta';
import * as api from '@/services/api';
import '@/assets/sass/widgets/widgets.scss';
import DashboardFilter from './components/dashboardFilter.vue';
    
useMeta({ title: 'Dashboard' });

const store = useStore();
const route = useRoute();
const router = useRouter();

const showFilter = ref(false);
const filter = reactive({
	creator: route.query.creator || [],
	createdDateGte: route.query.createdDateGte ? 
		moment(new Date(filter.createdDateGte)).format('YYYY-MM-DD') : null,
	createdDateLte: route.query.createdDateLte ? 
		moment(new Date(filter.createdDateLte)).format('YYYY-MM-DD') : null,
})
const applicationsDataLoading = ref(false);
const byTimeDataLoading = ref(false);
const bySourceDataLoading = ref(false);
const callsDataLoading = ref(false);
const earningsDataLoading = ref(false);
const netProfitDataLoading = ref(false);

const applicationsData = ref(null);
const byTimeData = ref(null);
const bySourceData = ref(null);
const callsData = ref(null);
const earningsData = ref(null);
const netProfitData = ref(null);

const sales_donut_series = computed(() => {
	if (applicationsData.value) {
		return [applicationsData.value.sended, applicationsData.value.closed]
	} else {
		return [0,0]
	}
});
const sales_time_donut_series = computed(() => {
	if (byTimeData.value) {
		return [byTimeData.value.day, byTimeData.value.night]
	} else {
		return [0,0]
	}
});
const sources_series = computed(() => {
	if (bySourceData.value) {
		return bySourceData.value
	} else {
		return []
	}
});
const unique_visitor_series = computed(() => {
	var unique = [];
	var target = [];
	if (callsData.value) {
		for (let i in callsData.value) {
			unique.push(callsData.value[i].unique | 0)
			target.push(callsData.value[i].target | 0)
		}
	}
	return [
		{ name: 'Уникальные', data: unique },
		{ name: 'Целевые', data: target },
	]
});
const earnings = computed(() => {
	if (earningsData.value) {
		return earningsData.value.value
	} else {
		return 0.00
	}
});
const netProfit = computed(() => {
	if (netProfitData.value) {
		return netProfitData.value.value
	} else {
		return 0.00
	}
});

//unique visitors
const unique_visitor_options = computed(() => {
	const is_dark = store.state.is_dark_mode;
	let categories = []
	for (let i in callsData.value) {
		categories.push(callsData.value[i].month)
	}
	return {
		chart: { toolbar: { show: false } },
		dataLabels: { enabled: false },
		stroke: { show: true, width: 2, colors: ['transparent'] },
		colors: ['#5c1ac3', '#ffbb44'],
		dropShadow: { enabled: true, opacity: 0.3, blur: 1, left: 1, top: 1, color: '#515365' },
		plotOptions: { bar: { horizontal: false, columnWidth: '55%', borderRadius: 10 } },
		legend: { position: 'bottom', horizontalAlign: 'center', fontSize: '14px', markers: { width: 12, height: 12 }, itemMargin: { horizontal: 0, vertical: 8 } },
		grid: { borderColor: is_dark ? '#191e3a' : '#e0e6ed' },
		xaxis: {
			categories: categories,
			axisBorder: { show: true, color: is_dark ? '#3b3f5c' : '#e0e6ed' },
		},
		yaxis: {
			tickAmount: 6,
		},
		fill: {
			type: 'gradient',
			gradient: { shade: is_dark ? 'dark' : 'light', type: 'vertical', shadeIntensity: 0.3, inverseColors: false, opacityFrom: 1, opacityTo: 0.8, stops: [0, 90] },
		},
		tooltip: {
			theme: is_dark ? 'dark' : 'light',
			y: {
				formatter: function (val) {
					return val;
				},
			},
		},
	};
});


//Заявки
const sales_donut_options = computed(() => {
	const is_dark = store.state.is_dark_mode;
	const option = {
		chart: {},
		dataLabels: { enabled: false },
		expandOnClick: is_dark ? false : true,
		stroke: { show: true, width: 25, colors: is_dark ? '#0e1726' : '#fff' },
		colors: is_dark ? ['#5c1ac3', '#e2a03f', '#e7515a', '#e2a03f'] : ['#e2a03f', '#5c1ac3', '#e7515a'],
		legend: {
			position: 'bottom',
			horizontalAlign: 'center',
			fontSize: '14px',
			markers: { width: 8, height: 8 },
			height: 40,
			offsetY: 16,
			itemMargin: { horizontal: 8, vertical: 0 },
		},
		plotOptions: {
			pie: {
				donut: {
					size: '65%',
					background: 'transparent',
					labels: {
						show: true,
						name: { show: true, fontSize: '21px', offsetY: -5 },
						value: {
							show: true,
							fontSize: '18px',
							color: is_dark ? '#bfc9d4' : undefined,
							offsetY: 4,
							formatter: function (val) {
								return val;
							},
						},
						total: {
							show: true,
							label: 'Всего',
							color: '#888ea8',
							fontSize: '21px',
							formatter: function (w) {
								return w.globals.seriesTotals.reduce(function (a, b) {
									return a + b;
								}, 0);
							},
						},
					},
				},
			},
		},
		labels: ['Переданные', 'Закрытые' ],
	};

	if (is_dark) {
		option['states'] = {
			hover: { filter: { type: 'none' } },
			active: { filter: { type: 'none' } },
		};
	}

	return option;
});
//Обращения по времени
const sales_time_donut_options = computed(() => {
	const is_dark = store.state.is_dark_mode;
	const option = {
		chart: {},
		dataLabels: { enabled: false },
		expandOnClick: is_dark ? false : true,
		stroke: { show: true, width: 25, colors: is_dark ? '#0e1726' : '#fff' },
		colors: is_dark ? ['#006fed', '#1abc9c', '#e7515a', '#e2a03f'] : ['#1abc9c', '#006fed', '#e7515a'],
		legend: {
			position: 'bottom',
			horizontalAlign: 'center',
			fontSize: '14px',
			markers: { width: 8, height: 8 },
			height: 40,
			offsetY: 16,
			itemMargin: { horizontal: 8, vertical: 0 },
		},
		plotOptions: {
			pie: {
				donut: {
					size: '65%',
					background: 'transparent',
					labels: {
						show: true,
						name: { show: true, fontSize: '21px',  offsetY: -5 },
						value: {
							show: true,
							fontSize: '18px',
							color: is_dark ? '#bfc9d4' : undefined,
							offsetY: 4,
							formatter: function (val) {
								return val;
							},
						},
						total: {
							show: true,
							label: 'Всего',
							color: '#888ea8',
							fontSize: '21px',
							formatter: function (w) {
								return w.globals.seriesTotals.reduce(function (a, b) {
									return a + b;
								}, 0);
							},
						},
					},
				},
			},
		},
		labels: ['с 8 до 20', 'с 20 до 8' ],
	};

	if (is_dark) {
		option['states'] = {
			hover: { filter: { type: 'none' } },
			active: { filter: { type: 'none' } },
		};
	}

	return option;
});

onMounted(async () => {
	let params = {};
	if (filter.createdDateGte) {
		params['created_date_gte'] = moment(new Date(filter.createdDateGte)).format('YYYY-MM-DD');
	}
	if (filter.createdDateLte) {
		params['created_date_lte'] = moment(new Date(filter.createdDateLte)).format('YYYY-MM-DD');
	}
	if (filter.creator) {
		params['creator'] = filter.creator;
	}
	await getDashboardApplications(params);
	await getDashboardByTime(params);
	await getDashboardBySource(params);
	await getDashboardCalls(params);
	await getDashboardEarnings(params);
	await getDashboardNetProfit(params);
});

const getDashboardApplications = async (params) => {
	applicationsDataLoading.value = true;
	try {
		let { data } = await api.getDashboardApplications(params);
		applicationsData.value = data;
	} catch(e) {
		console.log(e);
	} finally {
		applicationsDataLoading.value = false;
	}
};

const getDashboardByTime = async (params) => {
	byTimeDataLoading.value = true;
	try {
		let { data } = await api.getDashboardByTime(params);
		byTimeData.value = data;
	} catch(e) {
		console.log(e);
	} finally {
		byTimeDataLoading.value = false;
	}
};

const getDashboardBySource = async (params) => {
	bySourceDataLoading.value = true;
	try {
		let { data } = await api.getDashboardBySource(params);
		bySourceData.value = data;
	} catch(e) {
		console.log(e);
	} finally {
		bySourceDataLoading.value = false;
	}
}

const getDashboardCalls = async (params) => {
	callsDataLoading.value = true;
	try {
		let { data } = await api.getDashboardCalls(params);
		callsData.value = data;
	} catch(e) {
		console.log(e);
	} finally {
		callsDataLoading.value = false;
	}
}

const getDashboardEarnings = async (params) => {
	earningsDataLoading.value = true;
	try {
		let { data } = await api.getDashboardEarnings(params);
		earningsData.value = data;
	} catch(e) {
		console.log(e);
	} finally {
		earningsDataLoading.value = false;
	}
}

const getDashboardNetProfit = async (params) => {
	netProfitDataLoading.value = true;
	try {
		let { data } = await api.getDashboardNetProfit(params);
		netProfitData.value = data;
	} catch(e) {
		console.log(e);
	} finally {
		netProfitDataLoading.value = false;
	}
}

const addParamsToLocation = params => {
	router.push({ query: params });
}

const applyFilter = () => {
	let params = {};
	if (filter.createdDateGte) {
		params['created_date_gte'] = moment(new Date(filter.createdDateGte)).format('YYYY-MM-DD');
	}
	if (filter.createdDateLte) {
		params['created_date_lte'] = moment(new Date(filter.createdDateLte)).format('YYYY-MM-DD');
	}
	if (filter.creator) {
		params['creator'] = filter.creator;
	}
	addParamsToLocation(params);
	getDashboardApplications(params);
	getDashboardByTime(params);
	getDashboardBySource(params);
	getDashboardCalls(params);
	getDashboardEarnings(params);
	getDashboardNetProfit(params);
}
</script>
