import store from './store';
import { $themeConfig } from '@themeConfig';
import { getUserData } from '@/services/local-storage'

export default {
	init() {
		const userData = getUserData();
		store.commit('setUserData', userData || {});

		const menuStyle = localStorage.getItem('menu_style');
		store.commit('toggleMenuStyle', menuStyle || $themeConfig.navigation);

		const layoutStyle = localStorage.getItem('layout_style');
		store.commit('toggleLayoutStyle', layoutStyle || $themeConfig.layout);
	},
};
