import * as api from './api';
import { notification } from 'ant-design-vue';
import { clearToken, getRefreshToken, setToken, setUserData, clearUserData } from '@/services/local-storage'
import store from '@/store';
import { routesEnum } from '@/router/routes';
import { router } from '@/router';


class UserService {

	async login(loginData) {
		try {
			const res = await api.login(loginData);
			if (res.data.access && res.data.refresh) {
				store.commit('setUser', true);
				setToken({ user_email: loginData.email, access_token: res.data.access, refresh_token: res.data.refresh });

				const { data } = await api.getUsers({ email: loginData.email });
				const userData = data.results[0] || {};
				store.commit('setUserData', userData);
				setUserData(userData);

				return true;
			} else {
				notification.error({
					message: 'Ошибка',
					description: res?.data?.detail || 'Неизвестная ошибка'
				});
			}
		} catch (error) {
			notification.error({
				message: 'Ошибка',
				description: error?.response?.data?.detail || error.message
			});
		}
		return false;
	}

	async refreshToken() {
		const token = getRefreshToken();
		if (!token) return;

		return await api.refreshToken({ refresh: token })
	}


	logout() {
		clearToken();
		clearUserData();

		store.commit('unsetUser');
		store.commit('unsetUserData');

		router.go(routesEnum.login.path);
	}
}

export const userService = window.us = new UserService()
