<template>
  <!--  BEGIN SIDEBAR  -->
  <div class="sidebar-wrapper sidebar-theme">
    <nav id="sidebar" ref="menu">
      <div class="shadow-bottom" />

      <perfect-scrollbar
        class="list-unstyled menu-categories" tag="ul"
        :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }"
      >
        <li class="menu">
          <router-link to="/" class="dropdown-toggle" active-class="active" @click="toggleMobileMenu">
            <div class="">
              <svg
                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" class="feather feather-home"
              >
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                <polyline points="9 22 9 12 15 12 15 22" />
              </svg>
              <span>Дешборд</span>
            </div>
          </router-link>

          <ul id="dashboard" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
            <li>
              <router-link to="/" @click="toggleMobileMenu">
                Продажи
              </router-link>
            </li>
          </ul>
        </li>

        <li class="menu">
          <router-link :to="routesEnum.deals.path" class="dropdown-toggle" active-class="active" @click="toggleMobileMenu">
            <div class="">
              <svg
                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" class="feather feather-layout"
              >
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                <line x1="3" y1="9" x2="21" y2="9" />
                <line x1="9" y1="21" x2="9" y2="9" />
              </svg>
              <span>Сделки</span>
            </div>
          </router-link>
        </li>

        <li class="menu">
          <router-link :to="routesEnum.clients.path" class="dropdown-toggle" active-class="active" @click="toggleMobileMenu">
            <div class="">
              <svg
                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" class="feather feather-activity"
              >
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                <circle cx="9" cy="7" r="4" />
                <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
              </svg>
              <span>Заказчики</span>
            </div>
          </router-link>
        </li>
      </perfect-scrollbar>
    </nav>
  </div>
  <!--  END SIDEBAR  -->
</template>


<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import { routesEnum } from '../../router/routes'

export default {
	name: 'Sidebar',
	setup() {
		const store = useStore();

		const menu_collapse = ref('dashboard');

		onMounted(() => {
			const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
			if (selector) {
				const ul = selector.closest('ul.collapse');
				if (ul) {
					let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
					if (ele) {
						ele = ele[0];
						setTimeout(() => {
							ele.click();
						});
					}
				} else {
					selector.click();
				}
			}
		});

		const toggleMobileMenu = () => {
			if (window.innerWidth < 991) {
				store.commit('toggleSideBar', !store.state.is_show_sidebar);
			}
		};

		return {
			routesEnum,
			menu_collapse,
			toggleMobileMenu,
		}
	}
}
</script>
