import { onUnmounted } from 'vue'
import { createNanoEvents } from 'nanoevents';
import { getAccessToken } from '@/services/local-storage';

const WS_MESSAGE_TYPE = 'WS_MESSAGE';

class WebSocketProxy {
	constructor() {
		this.events = createNanoEvents();
		this.debug = process.env.VUE_APP_STATE === 'develop';
		this.connect();
	}

	getWsUrl() {
		const accessToken = getAccessToken();
		const wsHost = this.debug
			? 'ws://localhost:8000/'
			: process.env.VUE_APP_BACKEND_WS_URL;
		return `${wsHost}ws/calls/?bearer=${accessToken}`;
	}

	connect() {
		const accessToken = getAccessToken();
		if (!accessToken) {
			this.retry();
			return;
		}

		const wsUrl = this.getWsUrl();
		this.ws = new WebSocket(wsUrl);

		this.ws.onopen = () => {
			this.debug && console.log('✅ Подключено к webSocket');
		}
		this.ws.onmessage = ({ data }) => {
			this.debug && console.log('📩 Сообщение ', JSON.parse(data));
			this.events.emit(WS_MESSAGE_TYPE, JSON.parse(data));
		}

		this.ws.onclose = () => {
			this.debug && console.log('❌ Подключение закрыто. Попытка через 3 секунды...');
			this.retry();
		}
	}

	retry() {
		setTimeout(() => this.connect(), 3000)
	}

	on(callback) {
		return this.events.on(WS_MESSAGE_TYPE, callback);
	}
}

const ws = new WebSocketProxy();

export default function(callback) {
	const off = ws.on(callback);
	onUnmounted(off);
}
