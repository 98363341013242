<template>
  <div class="layout-px-spacing apps-invoice-add">
    <teleport to="#breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="routesEnum.deals.path">
                    Сделки
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <span>{{ orderId ? "Редактировать" : "Добавить" }}</span>
                </li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
    </teleport>

    <div class="row invoice layout-top-spacing layout-spacing">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="doc-container">
          <div class="row">
            <div class="col-xl-12">
              <div class="invoice-content">
                <div v-if="orderForm.call" class="invoice-detail-body mt-0 mb-3">
                  <div class="invoice-detail-header">
                    <h5>Звонок: </h5>С номера "{{ orderForm.call?.phone || '' }}" на номер "{{ orderForm.call?.subscriber?.phone || '' }}"
                  </div>
                </div>
                <a-form
                  ref="orderFormRef"
                  :model="orderForm"
                  :rules="rules"
                  layout="vertical"
                >
                  <div class="invoice-detail-body mb-0">
                    <div class="invoice-detail-header">
                      <div class="invoice-address-client-fields">
                        <h5>Заявка:</h5>
                        <a-row :gutter="16">
                          <a-col :span="8" :md="8" :xs="24">
                            <a-form-item
                              name="agent"
                              :validate-status="errors.agent ? 'error' : null"
                              :help="errors.agent ? errors.agent : false"
                            >
                              Наше юр.лицо*
                              <a-select
                                v-model:value="orderForm.agent"
                                show-search
                                placeholder="Наше юр.лицо"
                                :default-active-first-option="false"
                                :show-arrow="false"
                                :filter-option="false"
                                :not-found-content="agents.fetching ? undefined : null"
                                :options="agents.data"
                                @search="getAgents"
                              >
                                <template v-if="agents.fetching" #notFoundContent>
                                  <a-spin size="small" />
                                </template>
                              </a-select>
                            </a-form-item>
                          </a-col>
                          <a-col :span="8" :md="8" :xs="24">
                            <a-form-item
                              name="creator"
                              :validate-status="errors.creator ? 'error' : null"
                              :help="errors.creator ? errors.creator : false"
                            >
                              Менеджер ОП*
                              <a-select
                                v-model:value="orderForm.creator"
                                show-search
                                placeholder="Менеджер отдела продаж"
                                :default-active-first-option="false"
                                :show-arrow="false"
                                :filter-option="false"
                                :not-found-content="salers.fetching ? undefined : null"
                                :options="salers.data"
                                @search="getSalers"
                              >
                                <template v-if="salers.fetching" #notFoundContent>
                                  <a-spin size="small" />
                                </template>
                              </a-select>
                            </a-form-item>
                          </a-col>
                          <a-col :span="8" :md="8" :xs="24">
                            <a-form-item
                              name="manager"
                              :validate-status="errors.manager ? 'error' : null"
                              :help="errors.manager ? errors.manager : false"
                            >
                              Менеджер КС*
                              <a-select
                                v-model:value="orderForm.manager"
                                show-search
                                placeholder="Менеджер клиентского сервиса"
                                :default-active-first-option="false"
                                :show-arrow="false"
                                :filter-option="false"
                                :not-found-content="managers.fetching ? undefined : null"
                                :options="managers.data"
                                @search="getManagers"
                              >
                                <template v-if="managers.fetching" #notFoundContent>
                                  <a-spin size="small" />
                                </template>
                              </a-select>
                            </a-form-item>
                          </a-col>
                          <a-col :span="8" :md="8" :xs="24">
                            <a-form-item
                              name="customer"
                              :validate-status="errors.customer ? 'error' : null"
                              :help="errors.customer ? errors.customer : false"
                            >
                              Заказчик*
                              <a-select
                                v-model:value="orderForm.customer"
                                show-search
                                placeholder="Заказчик"
                                :default-active-first-option="false"
                                :show-arrow="false"
                                :filter-option="false"
                                :not-found-content="customers.fetching ? undefined : null"
                                :options="customers.data"
                                @search="getCustomers"
                              >
                                <template v-if="customers.fetching" #notFoundContent>
                                  <a-spin size="small" />
                                </template>
                              </a-select>
                              <a-button
                                href="#"
                                class="mt-1"
                                type="primary"
                                @click="() => onClickAddCustomer()"
                              >
                                Добавить
                              </a-button>
                            </a-form-item>
                          </a-col>
                          <a-col :span="8" :md="8" :xs="24">
                            <a-form-item
                              name="createdDate"
                              :validate-status="errors.createdDate ? 'error' : null"
                              :help="errors.createdDate ? errors.createdDate : false"
                            >
                              Дата обращения*
                              <a-date-picker
                                v-model:value="orderForm.createdDate"
                                format="DD.MM.YYYY HH:mm"
                                show-time
                                placeholder="Дата обращения"
                              />
                            </a-form-item>
                          </a-col>
                          <a-col :span="8" :md="8" :xs="24">
                            <a-form-item
                              name="startDate"
                              :validate-status="errors.startDate ? 'error' : null"
                              :help="errors.startDate ? errors.startDate : false"
                            >
                              Дата начала работ*
                              <a-date-picker
                                v-model:value="orderForm.startDate"
                                format="DD.MM.YYYY"
                                placeholder="Дата начала работ"
                              />
                            </a-form-item>
                          </a-col>
                        </a-row>
                      </div>
                    </div>

                    <div class="invoice-detail-header">
                      <div class="invoice-address-client-fields">
                        <a-row :gutter="16">
                          <a-col :span="6" :md="6" :xs="24">
                            <a-form-item
                              name="region"
                              :validate-status="errors.region ? 'error' : null"
                              :help="errors.region ? errors.region : false"
                            >
                              Регион*
                              <dadata-suggestions
                                v-model="orderForm.region"
                                field-value="region_with_type"
                                :options="{
                                  bounds: 'region-area',
                                }"
                                type="ADDRESS"
                                :token="dadataToken"
                                class="ant-input"
                                placeholder="Регион"
                              />
                            </a-form-item>
                          </a-col>
                          <a-col :span="6" :md="6" :xs="24">
                            <a-form-item
                              name="city"
                              :validate-status="errors.city ? 'error' : null"
                              :help="errors.city ? errors.city : false"
                            >
                              Город*
                              <dadata-suggestions
                                v-model="orderForm.city"
                                field-value="city"
                                :options="{
                                  constraints: orderForm.region,
                                  bounds: 'city-settlement',
                                }"
                                type="ADDRESS"
                                :token="dadataToken"
                                class="ant-input"
                                placeholder="Город"
                              />
                            </a-form-item>
                          </a-col>
                          <a-col :span="6" :md="6" :xs="24">
                            <a-form-item
                              name="street"
                              :validate-status="errors.street ? 'error' : null"
                              :help="errors.street ? errors.street : false"
                              :class="{ 'ant-input-status-error': errors.street }"
                            >
                              Улица*
                              <dadata-suggestions
                                v-model="orderForm.street"
                                field-value="street_with_type"
                                :options="{
                                  constraints: orderForm.city,
                                  bounds: 'street',
                                }"
                                type="ADDRESS"
                                :token="dadataToken"
                                class="ant-input"
                                placeholder="Улица"
                              />
                            </a-form-item>
                          </a-col>
                          <a-col :span="6" :md="6" :xs="24">
                            <a-form-item
                              name="house"
                              :validate-status="errors.house ? 'error' : null"
                              :help="errors.house ? errors.house : false"
                            >
                              Дом
                              <a-input id="client-company" v-model:value="orderForm.house" type="text" placeholder="Дом" />
                            </a-form-item>
                          </a-col>
                        </a-row>
                      </div>
                    </div>

                    <div class="invoice-detail-note">
                      <div class="row">
                        <div class="col-md-12 align-self-center">
                          <a-form-item
                            name="comment"
                            :validate-status="errors.comment ? 'error' : null"
                            :help="errors.comment ? errors.comment : false"
                          >
                            Комментарий
                            <a-textarea v-model:value="orderForm.comment" :rows="3" placeholder="Заметки к заявке" />
                          </a-form-item>
                        </div>
                      </div>
                    </div>
                  </div>
                </a-form>

                <div class="invoice-detail-body mt-3 mb-0">
                  <div v-if="orderId" class="invoice-detail-header">
                    <file-add
                      :order-id="orderId"
                      :order-is-not-created="false"
                      show-list
                    />
                  </div>
                  <div v-else class="invoice-detail-header">
                    <file-add
                      ref="fileAddRef"
                      order-is-not-created
                      show-list
                    />
                  </div>
                </div>

                <div class="invoice-detail-body mt-3">
                  <div class="invoice-detail-header">
                    <a-button
                      class="btn btn-dark btn-preview"
                      @click="() => submit(false)"
                    >
                      Сохранить
                    </a-button>

                    <a-button
                      class="btn btn-success btn-download mx-3"
                      @click="() => submit(true)"
                    >
                      Отправить менеджеру КС
                    </a-button>

                    <a-alert v-if="orderFormAlert" message="Ошибка! Проверьте форму." type="error" class="mt-3" show-icon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, reactive, watch, computed, nextTick } from 'vue';
import '@/assets/sass/apps/invoice-edit.scss';
import { debounce } from 'lodash';
import dayjs from 'dayjs';
import { routesEnum } from '@/router/routes';
import { useRouter, useRoute } from 'vue-router';
import { notification } from 'ant-design-vue';
import DadataSuggestions from 'vue-dadata-suggestions/src/components/DadataSuggestions.vue'
import { useMeta } from '@/composables/use-meta';
import * as api from '@/services/api';
import store from '@/store'
import FileAdd from '@/views/deals/components/fileAdd.vue';

useMeta({ title: 'Добавить сделку' });

const dadataToken = 'd5dc4ed47fa3a56220fefad8e6a20c9fd5846693'

const router = useRouter();
const route = useRoute();

const orderId = ref();
const agentId = ref();

const orderFormAlert = ref(false);
const orderForm = reactive({
	call: null,
	agent: null,
	customer: null,
	creator: null,
	manager: null,
	createdDate: null,
	startDate: null,
	comment: '',
	region: '',
	city: '',
	street: '',
	house: '',
});
const orderFormRef = ref();
const fileAddRef = ref();
const errors = reactive({});

const agents = reactive({
	data: [],
	fetching: false,
});
const customers = reactive({
	data: [],
	fetching: false,
});
const salers = reactive({
	data: [],
	fetching: false,
});
const managers = reactive({
	data: [],
	fetching: false,
});

const sended = ref(false);
const userData = computed(() => store.getters.userData);

const salerToLabel = (saler) => `${saler.lastName} ${saler.firstName} ${saler.middleName}`;

watch(
	() => userData.value,
	(newVal) => {
		if (newVal && !orderForm.creator) {
			if (!salers.data.some((el) => el.id === newVal.id)) {
				salers.data.push({
					value: newVal.id,
					label: salerToLabel(newVal),
				});
			}

			nextTick(() => orderForm.creator = newVal.id);
		}
	},
	{ immediate: true, deep: true },
);

const getAgents = debounce(async value => {
	agents.data = [];
	agents.fetching = true;
	try {
		const { data } = await api.getAgents({search: value, agent_role: 'AGENT'});
		agents.data = data.results.map(agent => ({
			label: `${agent.name}`,
			value: agent.id,
		}));
	} catch(e) {
		console.log(e);
	} finally {
		agents.fetching = false;
	}
}, 300);

const getCustomers = debounce(async value => {
	customers.data = [];
	customers.fetching = true;
	try {
		const { data } = await api.getAgents({search: value, agent_role: 'CUSTOMER'});
		customers.data = data.results.map(customer => ({
			label: `${customer.name}`,
			value: customer.id,
		}));
	} catch(e) {
		console.log(e);
	} finally {
		customers.fetching = false;
	}
}, 300);

const getSalers = debounce(async value => {
	salers.data = [];
	salers.fetching = true;

	try {
		const { data } = await api.getUsers({search: value, saler: true});
		salers.data = data.results.map(saler => ({
			value: saler.id,
			label: salerToLabel(saler),
		}));
	} catch(e) {
		console.log(e);
	} finally {
		salers.fetching = false;
	}
}, 300);

const getManagers = debounce(async value => {
	managers.data = [];
	managers.fetching = true;
	try {
		const { data } = await api.getUsers({search: value, manager: true});
		managers.data = data.results.map(manager => ({
			label: `${manager.lastName} ${manager.firstName} ${manager.middleName}`,
			value: manager.id,
		}));
	} catch(e) {
		console.log(e);
	} finally {
		managers.fetching = false;
	}
}, 300);

const submit = async (toWork) => {
	orderFormRef.value
		.validate()
		.then(async () => {
			orderFormAlert.value = false;
			let data = {};
			data['agent'] = orderForm.agent;
			data['customer'] = orderForm.customer;
			data['creator'] = orderForm.creator;
			data['manager'] = orderForm.manager;
			data['createdDate'] = dayjs(orderForm.createdDate).format('DD.MM.YYYY HH:mm');
			data['startDate'] = dayjs(orderForm.startDate).format('DD.MM.YYYY');
			data['comment'] = orderForm.comment;
			data['region'] = orderForm.region;
			data['city'] = orderForm.city;
			data['street'] = orderForm.street;
			data['house'] = orderForm.house;

			try {
				sended.value = true;

				if (orderId.value) {
					const { status } = await api.editOrder(orderId.value, data);
					if (status === 200) {
						if (toWork) {
							await sendOrderToWork(orderId.value);
						}
						await router.push(routesEnum.deals.path);
						await notification.success({
							message: 'Изменения сохранены!',
						});
					}
				} else {
					const { data: newOrder, status } = await api.createOrder(data);
					if (status === 201) {
						await fileAddRef.value.createFiles(newOrder.id);
						if (toWork) {
							await sendOrderToWork(newOrder.id);
						}
						await router.push(routesEnum.deals.path);
						await notification.success({
							message: 'Сделка создана!',
						});
					}
				}
			} catch (err) {
				console.log(err);
				const errData = err?.response?.data;
				for (const errKey in errData) {
					if (errKey in errData && errData[errKey]) {
						errors[errKey] = errData[errKey].join(', ');
					}
				}
				sended.value = false;
			}
		})
		.catch(() => {
			orderFormAlert.value = true;
		});
};

const sendOrderToWork = async (orderId) => {
	try {
		await api.sendOrderToWork(orderId);
	} catch(e) {
		console.log(e);
	}
};

const onClickAddCustomer = () => {
	window.open(routesEnum.clientAdd.path, '_blank');
}

const validateRequired = async (rule, value) => {
	if (value === '' || value === null || value === undefined) {
		const errorText = 'Поле обязательно для заполнения';
		errors[rule.field] = errorText;
		return Promise.reject(errorText);
	}

	delete errors[rule.field];
	return Promise.resolve();
}

const rules = {
	agent: [{ validator: validateRequired, trigger: 'change' }],
	customer: [{ validator: validateRequired, trigger: 'change' }],
	creator: [{ validator: validateRequired, trigger: 'change' }],
	manager: [{ validator: validateRequired, trigger: 'change' }],
	createdDate: [{ validator: validateRequired, trigger: 'change' }],
	startDate: [{ validator: validateRequired, trigger: 'change' }],
	region: [{ validator: validateRequired, trigger: 'change' }],
	city: [{ validator: validateRequired, trigger: 'change' }],
	street: [{ validator: validateRequired, trigger: 'change' }],
};

const initForm = async () => {
  if (agentId.value){
		await getCustomers(agentId.value);
		orderForm.customer = parseInt(agentId.value);
		await getAgents('');
		await getSalers('');
		await getManagers('');
	} else if (orderId.value) {
		try {
			let { data } = await api.getDetailOrder(orderId.value, {expand:'call'})
			await getCustomers(data.customer);
			await getAgents(data.agent);
			await getSalers(data.creator);
			await getManagers(data.manager);
			orderForm.call = data.call;
			orderForm.agent = data.agent;
			orderForm.customer = data.customer;
			orderForm.creator = data.creator;
			orderForm.manager = data.manager;
			orderForm.createdDate = dayjs(data.createdDate, 'DD.MM.YYYY HH:mm');
			orderForm.startDate = data.startDate ? dayjs(data.startDate, 'DD.MM.YYYY') : null;
			orderForm.comment = data.comment;
			orderForm.region = data.region;
			orderForm.city = data.city;
			orderForm.street = data.street;
			orderForm.house = data.house;
		} catch (e) {
			console.log(e);
		}
	} else {
		await getCustomers('');
		await getAgents('');
		await getSalers('');
		await getManagers('');
	}
}

onMounted(async () => {
  orderId.value = parseInt(route.params.id);
  agentId.value = parseInt(route.params.agentId);

  await initForm();
});
</script>
