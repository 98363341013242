<template>
  <div class="layout-px-spacing">
    <teleport to="#breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="routesEnum.clients.path">
                    Заказчики
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <span>{{ agentData.name }}</span>
                </li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
    </teleport>

    <div class="row invoice layout-top-spacing layout-spacing apps-invoice">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <a-spin v-if="!agentDataLoaded" size="large" />
        <div v-else class="doc-container">
          <div class="row">
            <div class="col-xl-9 mb-3">
              <div class="invoice-container">
                <div class="invoice-inbox">
                  <div id="ct" class="">
                    <div class="invoice-00001">
                      <div class="content-section">
                        <div class="inv--head-section inv--detail-section">
                          <div class="row">
                            <div class="col-sm-6 col-12 me-auto" />

                            <div class="col-sm-6 text-sm-end">
                              <p class="inv-list-number">
                                <span class="inv-title">Наименование : </span> <span>{{ agentData.name }}</span>
                              </p>
                            </div>

                            <div class="col-sm-6 align-self-center mt-3">
                              <p class="inv-street-addr">
                                <span class="inv-title">Наше юр.лицо : </span> <span>{{ agentData.defaultAgent?.name || "-" }}</span>
                              </p>
                              <p class="inv-email-address">
                                <span class="inv-title">Менеджер ОП : </span> <span>{{ agentData.creator?.fullName || "-" }}</span>
                              </p>
                              <p class="inv-email-address">
                                <span class="inv-title">Менеджер КС : </span> <span>{{ agentData.manager?.fullName || "-" }}</span>
                              </p>
                            </div>
                            <div class="col-sm-6 align-self-center mt-3 text-sm-end">
                              <p class="inv-created-date">
                                <span class="inv-title">Дата регистрации : </span> <span>{{ agentData.createdDate }}</span>
                              </p>
                              <p class="inv-due-date">
                                <span class="inv-title">Дата первого заказа : </span> <span>{{ agentData.firstApp?.createdDate || "-" }}</span>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="inv--detail-section inv--customer-detail-section">
                          <div class="row">
                            <div class="col-xl-8 col-lg-7 col-md-6 col-sm-4 align-self-center">
                              <p class="inv-to">
                                Реквизиты заказчика
                              </p>
                            </div>

                            <div class="col-xl-8 col-lg-7 col-md-6 col-sm-4">
                              <p class="inv-email-address">
                                <span class="inv-title">Город : </span> <span>{{ agentData.city }}</span>
                              </p>
                              <p class="inv-email-address">
                                <span class="inv-title">Контактный телефон : </span> <span>{{ agentData.number || "-" }}</span>
                              </p>
                              <p class="inv-email-address">
                                <span class="inv-title">Контактное лицо : </span> <span>-</span>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="inv--product-table-section">
                          <div class="table-responsive">
                            <table class="table table-hover">
                              <thead>
                                <tr>
                                  <th v-for="item in columns" :key="item.key" :class="[item.class]">
                                    {{ item.label }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <template v-for="item in items" :key="item.id">
                                  <tr v-if="items.length !== 0">
                                    <td>
                                      <router-link :to="routesEnum.dealPreview.path.replace(':id', item.id)">
                                        {{ item.id }}
                                      </router-link>
                                    </td>
                                    <td>
                                      {{ item.date }}
                                    </td>
                                  </tr>
                                  <tr v-else>
                                    <td colspan="2">
                                      Сделок не найдено
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="inv--note">
                          <div class="row mt-4">
                            <div class="col-sm-12 col-12 order-sm-0 order-1">
                              <p>Коментарий к заказчику: {{ agentData.description || "-" }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3">
              <div class="invoice-actions-btn mt-0">
                <div class="invoice-action-btn">
                  <div class="row">
                    <div class="col-xl-12 col-md-3 col-sm-6">
                      <a href="javascript:;" class="btn btn-secondary btn-print action-print" @click="print()">Печать</a>
                    </div>
                    <div class="col-xl-12 col-md-3 col-sm-6">
                      <router-link :to="routesEnum.dealAgentAdd.path.replace(':agentId', id)" class="btn btn-success btn-download">
                        Создать заявку
                      </router-link>
                    </div>
                    <div class="col-xl-12 col-md-3 col-sm-6">
                      <router-link :to="routesEnum.clientEdit.path.replace(':id', id)" class="btn btn-dark btn-edit">
                        Редактировать
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router'
import { useMeta } from '@/composables/use-meta';
import * as api from '@/services/api';
import { routesEnum } from '@/router/routes'

export default {
	name: 'AgentPreview',
	setup() {
		useMeta({ title: 'Просмотр заказчика' });

		const route = useRoute();
		const id = ref(route.params.id)
		const agentDataLoaded = ref(false);
		const agentData = ref({
			name: '',
		});

		const items = ref([]);
		const columns = ref([]);

		onMounted(async () => {
			try {
				let { data } = await api.getDetailAgent(id.value, {expand: 'defaultAgent,creator,manager'})
				agentData.value = data;
				agentDataLoaded.value = true;
			} catch (e) {
				console.log(e);
			}
			bind_data();
		})

		const bind_data = async () => {
			columns.value = [
				{ key: 'id', label: 'Номер сделки' },
				{ key: 'date', label: 'Дата' },
			];

			try {
				let { data } = await api.getOrders({customer: id.value});
				items.value = data.results.map(order => ({
					id: order.id,
					date: order.createdDate,
				}));
			} catch(e) {
				console.log(e);
			}
		};

		const print = () => {
			window.print();
		};
		return {
			id,
			agentDataLoaded,
			agentData,
			items,
			columns,
			routesEnum,
			print,
		}
	}
}
</script>