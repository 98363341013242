<template>
  <div class="layout-px-spacing apps-invoice-add">
    <teleport to="#breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="routesEnum.clients.path">
                    Заказчики
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <span>{{ agentId ? "Редактировать" : "Добавить" }}</span>
                </li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
    </teleport>

    <div class="row invoice layout-top-spacing layout-spacing">
      <div v-if="!agentId" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <a-radio-group class="mb-3" button-style="solid" :value="agentForm.agentType" @update:value="changeForm">
          <a-radio-button value="PHYSICAL">
            Физ. лицо
          </a-radio-button>
          <a-radio-button :value="agentForm.agentType==='INDIVIDUAL' ? 'INDIVIDUAL' : 'LEGAL'">
            Юр. лицо
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="doc-container">
          <div class="row">
            <div class="col-xl-12">
              <div class="invoice-content">
                <a-form
                  ref="agentFormRef"
                  :model="agentForm"
                  :rules="rules"
                >
                  <div class="invoice-detail-body mb-0">
                    <div class="invoice-detail-header">
                      <h5>Наше юр.лицо:</h5>
                      <a-row :gutter="16">
                        <a-col :span="8" :md="8" :xs="24">
                          <a-form-item
                            name="defaultAgent"
                            :validate-status="errors.defaultAgent ? 'error' : null"
                            :help="false"
                            :extra="errors.defaultAgent ? errors.defaultAgent : ' '"
                            :has-feedback="!!errors.defaultAgent"
                          >
                            Наше юр.лицо*
                            <a-select
                              v-model:value="agentForm.defaultAgent"
                              show-search
                              placeholder="Наше юр.лицо"
                              :default-active-first-option="false"
                              :show-arrow="false"
                              :filter-option="false"
                              :not-found-content="agents.fetching ? undefined : null"
                              :options="agents.data"
                              @search="getAgents"
                            >
                              <template v-if="agents.fetching" #notFoundContent>
                                <a-spin size="small" />
                              </template>
                            </a-select>
                          </a-form-item>
                        </a-col>
                        <a-col :span="8" :md="8" :xs="24">
                          <a-form-item
                            name="creator"
                            :validate-status="errors.creator ? 'error' : null"
                            :help="false"
                            :extra="errors.creator ? errors.creator : ' '"
                            :has-feedback="!!errors.creator"
                          >
                            Менеджер ОП*
                            <a-select
                              v-model:value="agentForm.creator"
                              show-search
                              placeholder="Менеджер отдела продаж"
                              :default-active-first-option="false"
                              :show-arrow="false"
                              :filter-option="false"
                              :not-found-content="salers.fetching ? undefined : null"
                              :options="salers.data"
                              @search="getSalers"
                            >
                              <template v-if="salers.fetching" #notFoundContent>
                                <a-spin size="small" />
                              </template>
                            </a-select>
                          </a-form-item>
                        </a-col>
                        <a-col :span="8" :md="8" :xs="24">
                          <a-form-item
                            name="manager"
                            :validate-status="errors.manager ? 'error' : null"
                            :help="false"
                            :extra="errors.manager ? errors.manager : ' '"
                            :has-feedback="!!errors.manager"
                          >
                            Менеджер КС*
                            <a-select
                              v-model:value="agentForm.manager"
                              show-search
                              placeholder="Менеджер клиентского сервиса"
                              :default-active-first-option="false"
                              :show-arrow="false"
                              :filter-option="false"
                              :not-found-content="managers.fetching ? undefined : null"
                              :options="managers.data"
                              @search="getManagers"
                            >
                              <template v-if="managers.fetching" #notFoundContent>
                                <a-spin size="small" />
                              </template>
                            </a-select>
                          </a-form-item>
                        </a-col>
                      </a-row>

                      <h5>Заказчик:</h5>
                      <a-row :gutter="16">
                        <a-col :span="agentForm.agentType!=='PHYSICAL' ? 6 : 8" :md="agentForm.agentType!=='PHYSICAL' ? 6 : 8" :xs="24">
                          <a-form-item
                            name="name"
                            :validate-status="errors.name ? 'error' : null"
                            :help="false"
                            :extra="errors.name ? errors.name : ' '"
                            :has-feedback="!!errors.name"
                          >
                            Наименование*
                            <a-input id="client-company" v-model:value="agentForm.name" type="text" placeholder="Наименование заказчика" />
                          </a-form-item>
                        </a-col>
                        <a-col v-if="agentForm.agentType!=='PHYSICAL'" :span="6" :md="6" :xs="24">
                          <a-form-item
                            name="inn"
                            :validate-status="errors.inn ? 'error' : null"
                            :help="false"
                            :extra="errors.inn ? errors.inn : ' '"
                            :has-feedback="!!errors.inn"
                          >
                            ИНН*
                            <dadata-suggestions
                              model="agentForm.inn"
                              :value="agentForm.inn"
                              field-value="inn"
                              type="PARTY"
                              :token="dadataToken"
                              class="ant-input"
                              placeholder="ИНН"
                              @change="onSelectInn"
                            />
                          </a-form-item>
                        </a-col>
                        <a-col :span="agentForm.agentType!=='PHYSICAL' ? 6 : 8" :md="agentForm.agentType!=='PHYSICAL' ? 6 : 8" :xs="24">
                          <a-form-item
                            name="number"
                            :validate-status="errors.number ? 'error' : null"
                            :help="false"
                            :extra="errors.number ? errors.number : ' '"
                            :has-feedback="!!errors.number"
                          >
                            Телефон
                            <a-input id="client-phone" v-model:value="agentForm.number" type="text" placeholder="(123) 456 789" />
                          </a-form-item>
                        </a-col>
                        <a-col :span="agentForm.agentType!=='PHYSICAL' ? 6 : 8" :md="agentForm.agentType!=='PHYSICAL' ? 6 : 8" :xs="24">
                          <a-form-item
                            name="city"
                            :validate-status="errors.city ? 'error' : null"
                            :help="false"
                            :extra="errors.city ? errors.city : ' '"
                            :has-feedback="!!errors.city"
                          >
                            Город*
                            <dadata-suggestions
                              v-model="agentForm.city"
                              :value="agentForm.city"
                              field-value="city"
                              type="ADDRESS"
                              :token="dadataToken"
                              class="ant-input"
                              placeholder="Город"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </div>
                    <div class="invoice-detail-note mt-0">
                      <div class="row">
                        <div class="col-md-12 align-self-center">
                          <a-form-item
                            name="description"
                            :validate-status="errors.description ? 'error' : null"
                            :help="false"
                            :extra="errors.description ? errors.description : ' '"
                            :has-feedback="!!errors.description"
                          >
                            Комментарий
                            <a-textarea v-model:value="agentForm.description" :rows="3" placeholder="Заметки к заказчику" />
                          </a-form-item>
                        </div>
                      </div>
                    </div>
                  </div>
                </a-form>
                <div class="invoice-detail-body mt-3 mb-0">
                  <div v-if="agentId" class="invoice-detail-header">
                    <contact-person-add
                      :agent-id="agentId"
                      :agent-is-not-created="false"
                      :show-list="true"
                    />
                  </div>
                  <div v-else class="invoice-detail-header">
                    <contact-person-add
                      ref="contactPersonAddRef"
                      :agent-is-not-created="true"
                      :show-list="true"
                    />
                  </div>
                </div>
                <div class="invoice-detail-body mt-3 mb-0">
                  <div v-if="agentId" class="invoice-detail-header">
                    <file-add
                      :agent-id="agentId"
                      :agent-is-not-created="false"
                      :show-list="true"
                    />
                  </div>
                  <div v-else class="invoice-detail-header">
                    <file-add
                      ref="fileAddRef"
                      :agent-is-not-created="true"
                      :show-list="true"
                    />
                  </div>
                </div>
                <div class="invoice-detail-body mt-3">
                  <div class="invoice-detail-header">
                    <button class="btn btn-dark btn-preview" :disabled="sended" @click="submit">
                      Сохранить
                    </button>
                    <a-alert v-if="agentFormAlert" message="Ошибка! Проверьте форму." type="error" class="mt-3" show-icon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import { onMounted, ref, reactive } from 'vue';
import '@/assets/sass/apps/invoice-edit.scss';
import { debounce } from 'lodash';
import { useMeta } from '@/composables/use-meta';
import * as api from '../../services/api';
import { useRouter, useRoute } from 'vue-router';
import DadataSuggestions from 'vue-dadata-suggestions/src/components/DadataSuggestions.vue'
import { routesEnum } from '@/router/routes'
import ContactPersonAdd from '@/views/clients/components/contactPersonAdd.vue';
import FileAdd from '@/views/clients/components/fileAdd.vue';

useMeta({ title: 'Добавить заказчика' });

const router = useRouter();
const route = useRoute();

const agentId = ref();
const phone = ref();

const dadataToken = 'd5dc4ed47fa3a56220fefad8e6a20c9fd5846693'

const sended = ref(false);
const errors = reactive({});
const agentFormAlert = ref(false);
const agentForm = reactive({
	agentType: 'PHYSICAL',
	defaultAgent: null,
	creator: null,
	manager: null,
	name: '',
	inn: '',
	number: '',
	city: '',
	description: '',
});
const agentFormRef = ref();
const fileAddRef = ref();
const contactPersonAddRef = ref();

const agents = reactive({
	data: [],
	fetching: false,
});
const salers = reactive({
	data: [],
	fetching: false,
});
const managers = reactive({
	data: [],
	fetching: false,
});

const getAgents = debounce(async value => {
	agents.data = [];
	agents.fetching = true;
	try {
		let { data } = await api.getAgents({search: value, agent_role: 'AGENT'});
		agents.data = data.results.map(agent => ({
			label: `${agent.name}`,
			value: agent.id,
		}));
	} catch(e) {
		console.log(e);
	} finally {
		agents.fetching = false;
	}
}, 300);

const getSalers = debounce(async value => {
	salers.data = [];
	salers.fetching = true;
	try {
		let { data } = await api.getUsers({search: value, saler: true});
		salers.data = data.results.map(saler => ({
			label: `${saler.lastName} ${saler.firstName} ${saler.middleName}`,
			value: saler.id,
		}));
	} catch(e) {
		console.log(e);
	} finally {
		salers.fetching = false;
	}
}, 300);

const getManagers = debounce(async value => {
	managers.data = [];
	managers.fetching = true;
	try {
		let { data } = await api.getUsers({search: value, manager: true});
		managers.data = data.results.map(manager => ({
			label: `${manager.lastName} ${manager.firstName} ${manager.middleName}`,
			value: manager.id,
		}));
	} catch(e) {
		console.log(e);
	} finally {
		managers.fetching = false;
	}
}, 300);

const validateRequired = (rule, value) => {
	let errorText;
	if (value === '' || value === null || value === undefined) {
		errorText = 'Поле обязательно для заполнения';
		errors[rule.field] = errorText;
		return Promise.reject(errorText);
	}
	delete errors[rule.field];
	return Promise.resolve();
}

const submit = async () => {
	agentFormRef.value
		.validate()
		.then(async () => {
			agentFormAlert.value = false;
			let data = {};
			data['defaultAgent'] = agentForm.defaultAgent;
			data['agentType'] = agentForm.agentType;
			data['name'] = agentForm.name;
			data['creator'] = agentForm.creator;
			data['manager'] = agentForm.manager;
			data['inn'] = agentForm.inn;
			data['number'] = agentForm.number;
			data['city'] = agentForm.city;
			data['description'] = agentForm.description;
			data['isCreatedBySalers'] = true;
			data['agentRole'] = 'CUSTOMER';

			try {
				sended.value = true;

				if (agentId.value){
					const res = await api.editAgent(agentId.value, data);
					if (res.status === 200) {
						await router.push(routesEnum.clientPreview.path.replace(':id', agentId.value));
						await notification.success({
							message: 'Изменения сохранены!',
						});
					}
				} else {
					const res = await api.createAgent(data);
					if (res.status === 201) {
						const newAgent = res.data;
						contactPersonAddRef.value.createContactPersons(newAgent.id);
						fileAddRef.value.createFiles(newAgent.id);
						await router.push(routesEnum.clientPreview.path.replace(':id', newAgent.id));
						await notification.success({
							message: 'Заказчик создан!',
						});
					}
				}
			} catch (err) {
				const errData = err?.response?.data;
				for (const errKey in errData) {
					if (errKey in errData && errData[errKey]) {
						errors[errKey] = errData[errKey].join(', ');
					}
				}
				sended.value = false;
			}
		})
		.catch(() => {
			agentFormAlert.value = true;
		});
};

const rules = {
	defaultAgent: [{ validator: validateRequired, trigger: 'change' }],
	creator: [{ validator: validateRequired, trigger: 'change' }],
	manager: [{ validator: validateRequired, trigger: 'change' }],
	name: [{ validator: validateRequired, trigger: 'change' }],
	inn: agentForm.agentType === 'PHYSICAL' ? [] : [{ validator: validateRequired, trigger: 'change' }],
	city: [{ validator: validateRequired, trigger: 'change' }],
};

const changeForm = async (value) => {
	agentForm.agentType = value;
}

const onSelectInn = async (suggestion) => {
	if (suggestion.value){
		agentForm.name = suggestion.value
		agentForm.inn = suggestion.data.inn
		agentForm.agentType = suggestion.data.type
	}
}

const initForm = async () => {
  if (agentId.value){
		try {
			let { data } = await api.getDetailAgent(agentId.value)
			await getAgents(data.defaultAgent);
			await getSalers(data.creator);
			await getManagers(data.manager);
			agentForm.defaultAgent = data.defaultAgent;
			agentForm.creator = data.creator;
			agentForm.agentType = data.agentType;
			agentForm.manager = data.manager;
			agentForm.name = data.name;
			agentForm.inn = data.inn;
			agentForm.number = data.number;
			agentForm.city = data.city;
			agentForm.description = data.description;
		} catch (e) {
			console.log(e);
		}
	} else {
		await getAgents('');
		await getSalers('');
		await getManagers('');
	}
	if (phone.value) {
		agentForm.number = phone.value;
	}
}

onMounted(async () => {
  agentId.value = parseInt(route.params.id);
  phone.value = route.params.phone;

	await initForm();
});
</script>