import {
	ConfigProvider,
	Table,
	Tabs,
	message,
	Modal,
	Select,
	Input,
	DatePicker,
	Button,
	Radio,
	RadioGroup,
	Switch,
	Form,
	FormItem,
	Pagination,
	TreeSelect,
	Spin,
	Checkbox,
	Row,
	Col,
	Upload,
	Popconfirm,
	Alert,
	Badge,
	Divider,
} from 'ant-design-vue';
import scrollTo from 'ant-design-vue/lib/_util/scrollTo';

export default {
  install: (app) => {
    app
      .use(ConfigProvider)
      .use(Table)
      .use(Tabs)
      .use(Select)
      .use(Input)
      .use(DatePicker)
      .use(Button)
      .use(Radio)
      .use(RadioGroup)
      .use(Switch)
      .use(Form)
      .use(FormItem)
      .use(Modal)
      .use(Pagination)
      .use(TreeSelect)
      .use(Spin)
      .use(Checkbox)
      .use(Row)
      .use(Col)
      .use(Upload)
      .use(Popconfirm)
      .use(Alert)
      .use(Badge)
      .use(Divider);

    app.config.globalProperties.$message = message;
    app.config.globalProperties.$scrollTo = scrollTo;

    app.config.globalProperties.$confirm = Modal.confirm;
  }
};
