import axios from 'axios';
import {
	setAccessToken,
	getAccessToken,
	getRefreshToken
} from './local-storage'
import { userService } from './user';
import qs from 'qs';


const BASE_URL = process.env.VUE_APP_STATE === 'production'
	? process.env.VUE_APP_BACKEND_API_URL
	: 'http://localhost:8000/';

export const http = new axios.create({
	baseURL: `${BASE_URL}api/`,
	timeout: 1000 * 60,
	paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
})

// Dynamically adding an Authorization header to request
http.interceptors.request.use(
	config => {
		const token = getAccessToken();
		if (token) {
			config.headers['Authorization'] = 'Bearer ' + token;
		}
		return config;
	},
	error => Promise.reject(error),
)

http.interceptors.response.use(
	(response) => response,
	async error => {
		const originalRequest = error.config;

		if (error.response?.status === 401 && originalRequest.url.includes('auth/token/refresh')) {
			userService.logout();
			return Promise.reject(error);
		}

		if (error.response?.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;

			if (getRefreshToken()) {
				try {
					const res = await userService.refreshToken()
					if (res?.status === 200 && res?.data?.access) {
						setAccessToken(res.data.access);
						return http(originalRequest);
					}
				} catch (e) {
					return Promise.reject(error);
				}
			}
		}

		return Promise.reject(error);
	}
)

export default http;
