<template>
  <div class="p-3">
    <a-row :gutter="16" class="mb-3">
      <a-col :span="8" :md="8" :xs="24">
        <a-input
          v-model:value="modelValue.phoneNumber"
          placeholder="Номер телефона"
        />
      </a-col>
      <a-col :span="8" :md="8" :xs="24">
        <a-select
          v-model:value="modelValue.customer"
          mode="multiple"
          show-search
          placeholder="Контрагент"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="customers.fetching ? undefined : null"
          :options="customers.data"
          @search="getCustomers"
        >
          <template v-if="customers.fetching" #notFoundContent>
            <a-spin size="small" />
          </template>
        </a-select>
      </a-col>
      <a-col :span="4" :md="4" :xs="24">
        <a-date-picker v-model:value="modelValue.createdDateGte" format="YYYY-MM-DD" show-time placeholder="Дата (с)" />
      </a-col>
      <a-col :span="4" :md="4" :xs="24">
        <a-date-picker v-model:value="modelValue.createdDateLte" format="YYYY-MM-DD" show-time placeholder="Дата (по)" />
      </a-col>
    </a-row>
    <a-row :gutter="16" class="mb-3">
      <a-col :span="8" :md="8" :xs="24">
        <a-select
          v-model:value="modelValue.agent"
          show-search
          placeholder="Наше юр.лицо"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="agents.fetching ? undefined : null"
          :options="agents.data"
          @search="getAgents"
        >
          <template v-if="agents.fetching" #notFoundContent>
            <a-spin size="small" />
          </template>
        </a-select>
      </a-col>
      <a-col :span="8" :md="8" :xs="24">
        <a-select
          v-model:value="modelValue.creator"
          mode="multiple"
          show-search
          placeholder="Менеджер отдела продаж"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="salers.fetching ? undefined : null"
          :options="salers.data"
          @search="getSalers"
        >
          <template v-if="salers.fetching" #notFoundContent>
            <a-spin size="small" />
          </template>
        </a-select>
      </a-col>
      <a-col :span="8" :md="8" :xs="24">
        <a-select
          v-model:value="modelValue.manager"
          mode="multiple"
          show-search
          placeholder="Менеджер клиентского сервиса"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="managers.fetching ? undefined : null"
          :options="managers.data"
          @search="getManagers"
        >
          <template v-if="managers.fetching" #notFoundContent>
            <a-spin size="small" />
          </template>
        </a-select>
      </a-col>
    </a-row>
    
    <button class="btn btn-success btn-download" @click="searchHandler">
      Найти
    </button>
    <button class="btn btn-dark mx-3" @click="resetHandler">
      Сбросить фильтр
    </button>
  </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue';
import { debounce } from 'lodash';
import 'moment/dist/locale/ru';
import * as api from '@/services/api';

const modelValue = defineModel({ type: Object });

const emit = defineEmits(['update:modelValue', 'filter-changed']);

const searchHandler = () => {
	emit('update:modelValue', modelValue.value);
	emit('filter-changed');
}
const resetHandler = () => {
	modelValue.value.agent = null;
	modelValue.value.customer = [];
	modelValue.value.creator = [];
	modelValue.value.manager = [];
	modelValue.value.createdDateGte = null;
	modelValue.value.createdDateLte = null;
	modelValue.value.string = '';
	modelValue.value.phoneNumber = '';

	emit('update:modelValue', modelValue);
	emit('filter-changed');
}

onMounted(async () => {
	await getAgents('');
	await getCustomers('');
	await getSalers('');
	await getManagers('');
});

const agents = reactive({
	data: [],
	fetching: false,
});
const customers = reactive({
	data: [],
	fetching: false,
});
const salers = reactive({
	data: [],
	fetching: false,
});
const managers = reactive({
	data: [],
	fetching: false,
});

const getAgents = debounce(async value => {
	agents.data = [];
	agents.fetching = true;
	try {
		let { data } = await api.getAgents({search: value, agent_role: 'AGENT'});
		agents.data = data.results.map(agent => ({
			label: `${agent.name}`,
			value: agent.id,
		}));
	} catch(e) {
		console.log(e);
	} finally {
		agents.fetching = false;
	}
}, 300);

const getCustomers = debounce(async value => {
	customers.data = [];
	customers.fetching = true;
	try {
		let { data } = await api.getAgents({search: value, agent_role: 'CUSTOMER'});
		customers.data = data.results.map(customer => ({
			label: `${customer.name}`,
			value: customer.id,
		}));
	} catch(e) {
		console.log(e);
	} finally {
		customers.fetching = false;
	}
}, 300);

const getSalers = debounce(async value => {
	salers.data = [];
	salers.fetching = true;
	try {
		let { data } = await api.getUsers({search: value, saler: true});
		salers.data = data.results.map(saler => ({
			label: `${saler.lastName} ${saler.firstName} ${saler.middleName}`,
			value: saler.id,
		}));
	} catch(e) {
		console.log(e);
	} finally {
		salers.fetching = false;
	}
}, 300);

const getManagers = debounce(async value => {
	managers.data = [];
	managers.fetching = true;
	try {
		let { data } = await api.getUsers({search: value, manager: true});
		managers.data = data.results.map(manager => ({
			label: `${manager.lastName} ${manager.firstName} ${manager.middleName}`,
			value: manager.id,
		}));
	} catch(e) {
		console.log(e);
	} finally {
		managers.fetching = false;
	}
}, 300);
</script>