import Home from '../views/index.vue';
import Deals from '../views/deals/deals.vue';
import DealPreview from '../views/deals/preview.vue';
import DealAddEdit from '../views/deals/addEdit.vue';
import Clients from '../views/clients/clients.vue';
import ClientPreview from '../views/clients/preview.vue';
import ClientAddEdit from '../views/clients/addEdit.vue';
import Error404 from '../views/pages/error404.vue';
import Login from '../views/auth/login.vue';

const prefix = process.env.VUE_APP_STATE === 'production' ? (process.env.VUE_APP_PREFIX || '') : ''

export const routesEnum = {
	home: {
		name: 'Home',
		path: `${prefix}/`
	},
	login: {
		name: 'login',
		path: `${prefix}/login`
	},
	deals: {
		name: 'deals',
		path: `${prefix}/deals`
	},
	dealAdd: {
		name: 'dealAdd',
		path: `${prefix}/deals/add`
	},
	dealEdit: {
		name: 'dealEdit',
		path: `${prefix}/deals/edit/:id`
	},
	dealAgentAdd: {
		name: 'dealAgentAdd',
		path: `${prefix}/deals/add/:agentId`
	},
	dealPreview: {
		name: 'dealPreview',
		path: `${prefix}/deals/:id`
	},
	clients: {
		name: 'clients',
		path: `${prefix}/clients`
	},
	clientAdd: {
		name: 'clientAdd',
		path: `${prefix}/clients/add`
	},
	phoneClientAdd: {
		name: 'phoneClientAdd',
		path: `${prefix}/clients/add/:phone`
	},
	clientEdit: {
		name: 'clientEdit',
		path: `${prefix}/clients/edit/:id`
	},
	clientPreview: {
		name: 'clientPreview',
		path: `${prefix}/clients/:id`
	},
}

const routes = [
	{ path: `${prefix}/:pathMatch(.*)*`, name: 'NotFound', component: Error404 },
	{
		name: routesEnum.login.name,
		path: routesEnum.login.path,
		component: Login,
	},
	{
		name: routesEnum.home.name,
		path: routesEnum.home.path,
		component: Home,
	},
	{
		name: routesEnum.deals.name,
		path: routesEnum.deals.path,
		component: Deals,
	},
	{
		name: routesEnum.dealAdd.name,
		path: routesEnum.dealAdd.path,
		component: DealAddEdit,
	},
	{
		name: routesEnum.dealEdit.name,
		path: routesEnum.dealEdit.path,
		component: DealAddEdit,
	},
	{
		name: routesEnum.dealAgentAdd.name,
		path: routesEnum.dealAgentAdd.path,
		component: DealAddEdit,
	},
	{
		name: routesEnum.dealPreview.name,
		path: routesEnum.dealPreview.path,
		component: DealPreview,
	},
	{
		name: routesEnum.clients.name,
		path: routesEnum.clients.path,
		component: Clients,
	},
	{
		name: routesEnum.clientAdd.name,
		path: routesEnum.clientAdd.path,
		component: ClientAddEdit,
	},
	{
		name: routesEnum.phoneClientAdd.name,
		path: routesEnum.phoneClientAdd.path,
		component: ClientAddEdit,
	},
	{
		name: routesEnum.clientEdit.name,
		path: routesEnum.clientEdit.path,
		component: ClientAddEdit,
	},
	{
		name: routesEnum.clientPreview.name,
		path: routesEnum.clientPreview.path,
		component: ClientPreview,
	},
]

export default routes

