import { createStore } from 'vuex';
import { getRefreshToken } from '@/services/local-storage';

export default new createStore({
	state: {
		user: null,
		userData: {},
		initialCheckIsDone: false,
		layout: 'app',
		is_show_sidebar: true,
		is_show_search: false,
		is_dark_mode: false,
		dark_mode: 'light',
		locale: null,
		menu_style: 'vertical',
		layout_style: 'full',
		countryList: [
			{ code: 'ru', name: 'Russian' },
		],
	},
	mutations: {
		setLayout(state, payload) {
			state.layout = payload;
		},
		toggleSideBar(state, value) {
			state.is_show_sidebar = value;
		},
		toggleSearch(state, value) {
			state.is_show_search = value;
		},

		toggleMenuStyle(state, value) {
			//horizontal|vertical|collapsible-vertical
			value = value || '';
			localStorage.setItem('menu_style', value);
			state.menu_style = value;
			if (!value || value === 'vertical') {
				state.is_show_sidebar = true;
			} else if (value === 'collapsible-vertical') {
				state.is_show_sidebar = false;
			}
		},

		toggleLayoutStyle(state, value) {
			//boxed-layout|large-boxed-layout|full
			value = value || '';
			localStorage.setItem('layout_style', value);
			state.layout_style = value;
		},

		setUser(state, user) {
			state.user = user
		},
		unsetUser: (state) => state.user = null,

		setUserData: (state, data) => {
			state.userData = data;
		},
		unsetUserData: (state) => state.userData = {},

		setInitialCheckIsDone: (state) => state.initialCheckIsDone = true,
	},
	getters: {
		layout(state) {
			return state.layout;
		},
		isAuth(state) {
			return Boolean(state.user !== null && getRefreshToken())
		},
		userData(state) {
			return state.userData;
		}
	},
	actions: {},
	modules: {},
});
