<template>
  <h5 v-if="showList">
    Файлы:
  </h5>
  <div
    v-if="showList && files.length !== 0"
    id="file"
    class="mt-2"
  >
    <div
      v-for="file in files"
      id="file_list"
      :key="file.id"
    >
      <div
        class="line row m-0"
        :class="{file: file.id === editableFile}"
      >
        <div class="description col-8">
          {{ file.file.name || file.filename }}
        </div>
        <div class="col">
          <a-popconfirm
            title="Файл будет удален"
            popper-class="delete_file_confirm"
            placement="left"
            @confirm="() => deleteFile(file.id)"
          >
            <a-button class="btn btn-sm btn-danger">
              <DeleteOutlined />
            </a-button>
          </a-popconfirm>

          <a-button class="btn btn-sm btn-primary mx-2" @click="() => fillFileForm(file.id)">
            <EditOutlined />
          </a-button>
        </div>
      </div>
      <hr>
    </div>
    <br>
  </div>
  <div v-show="visible || !showList">
    <a-form
      ref="formRef"
      :model="form"
      :rules="rules"
    >
      <a-row :gutter="16">
        <a-col :span="24" :md="24" :xs="24">
          <a-form-item
            name="file"
            :validate-status="errors.file ? 'error' : null"
            :help="false"
            :extra="errors.file ? errors.file : ' '"
            :has-feedback="!!errors.file"
          >
            <a-upload :multiple="false" :show-upload-list="false" :file="form.file" name="file" :before-upload="beforeUpload">
              <a-button>
                Выберите файл
              </a-button>
            </a-upload>
            <div v-if="form.file" class="mt-4 flex items-center gap-1">
              <span class="flex-1">
                {{ form.file.name || form.filename }}
              </span>
              <a-button type="text" @click="removeUploadFile">
                Удалить
              </a-button>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-button
      v-show="!editFileVisible"
      id="file_form_add_button"
      class="project mt-2"
      size="mini"
      @click="validateForm()"
    >
      Добавить
    </a-button>
    <a-button
      v-show="visible && editFileVisible"
      id="file_form_save_button"
      size="mini"
      class="project mx-2"
      style="margin-left: 0!important;"
      @click="editFile()"
    >
      Сохранить изменения
    </a-button>
    <a-button
      v-show="visible && showList"
      id="file_form_close_button"
      class="mx-2"
      size="mini"
      @click="closeFileForm()"
    >
      Отмена
    </a-button>
    <a-alert v-if="fileFormAlert" message="Ошибка! Проверьте форму." type="error" class="mt-3" show-icon />
  </div>
  <a-button
    v-show="!visible && !editFileVisible && showList"
    id="file_add_button"
    size="mini"
    class="project"
    @click="visible = true"
  >
    Добавить файл
  </a-button>
</template>

<script setup>
import { ref, onMounted, reactive } from 'vue';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons-vue';
import * as api from '@/services/api';

const props = defineProps({
	agentId: {
		type: Number,
		required: false,
		default: -1,
	},
	showList: Boolean,
	agentIsNotCreated: Boolean,
});

const formRef = ref();

const emptyForm = {
	id: -1,
	agent: props.agentId ? props.agentId : -1,
	file: null,
};

const fileFormAlert = ref(false);
const form = reactive({ ...emptyForm });

const files = ref([]);

const editFileVisible = ref(false);

const editableFile = ref(-2);

const visible = ref(false);

const idForCreation = ref(-1);

const errors = reactive({});

const validateRequired = (rule, value) => {
	let errorText;
	if (value === '' || value === null || value === undefined) {
		errorText = 'Поле обязательно для заполнения';
		errors[rule.field] = errorText;
		return Promise.reject(errorText);
	}
	delete errors[rule.field];
	return Promise.resolve();
}

const rules = {
	file: [
		{
			validator: validateRequired,
			trigger: 'change',
		},
	],
}

const validateForm = async () => {
	formRef.value.validate()
		.then(async () => {
			fileFormAlert.value = false;
			idForCreation.value--;
			if (props.agentIsNotCreated) {
				files.value.push({ ...form, id: idForCreation.value });
				console.log(files.value)
				closeFileForm();
			} else {
				createFile();
				visible.value = false;
			}
		}).catch(() => {
			fileFormAlert.value = true;
		});
}
const removeUploadFile = () => form.file = null;
const beforeUpload = (file) => {
	form.file = file;
	return false;
};

const createFile = () => {
	const formData = new FormData();
	formData.append('file', form.file);
	formData.append('agent', props.agentId);
	api.createAgentFile(formData)
		.then((response) => {
			getFiles('');
			if (!props.showList) $emit('agent-file-created', response.data);
		}).catch((error) => {
			console.log(error);
		}).finally(() => {
			closeFileForm();
		});
}

const clearForm = () => {
	Object.assign(form, { ...emptyForm });
	formRef.value.clearValidate();
}

const editFile = async () => {
	formRef.value.validate().then(() => {
		fileFormAlert.value = false;
		const file = files.value.filter((item) => item.id === editableFile.value)[0];
		if (props.agentIsNotCreated) {
			Object.assign(files.value[files.value.indexOf(file)], { ...form })
			closeFileForm();
		} else {
			const formData = new FormData();
			formData.append('file', form.file);
			formData.append('agent', props.agentId);
			api.editAgentFile(editableFile.value, formData)
				.then(() => getFiles())
				.catch((error) => console.log(error))
				.finally(() => closeFileForm());
		}
	}
	).catch(() => {
		fileFormAlert.value = true;
	});
}

const closeFileForm = () => {
	clearForm();
	visible.value = false;
	editableFile.value = 0;
	editFileVisible.value = false;
}

const deleteFile = (id) => {
	if (id === editableFile.value) closeFileForm();
	if (!props.agentIsNotCreated) {
		api.deleteAgentFile(id)
			.then(() => {
				getFiles();
			}).catch((error) => { console.log(error); })
			.finally(() => {
			});
	} else {
		files.value = files.value.filter((item) => item.id !== id);
	}
}

const fillFileForm = (id) => {
	let fill_form = { ...files.value.filter((item) => item.id === id)[0] }
	Object.assign(form, fill_form);
	editableFile.value = id;
	editFileVisible.value = true;
	visible.value = true;
}

const createFiles = (agentId) => {
	const promises = [];

	files.value.filter((item) => item.id < 0).forEach((item) => {
		promises.push(
			new Promise((resolve) => {
				const formData = new FormData();
				formData.append('file', item.file);
				formData.append('agent', agentId);
				api.createAgentFile(formData)
					.then((response) => {
						const index = files.value.findIndex((file) => file.id === item.id);
						files.value[index] = response.data;
					}).finally(() => (resolve()));
			}),
		);
	});
	return Promise.all(promises);
}

const getFiles = async () => {
	if (!props.agentIsNotCreated && props.agentId) {
		try {
			let { data } = await api.getAgentFiles({ agent: props.agentId })
			files.value = data.results;
		} catch (e) {
			console.log(e);
		}
	}
}

defineExpose({ createFiles });

onMounted(() => {
	getFiles();
});
</script>
