<template>
  <div class="form auth-boxed">
    <div class="form-container outer">
      <div class="form-form">
        <div class="form-form-wrap">
          <div class="form-container">
            <div class="form-content">
              <h1 class="">
                Авторизация SE
              </h1>

              <form class="text-start" @submit.prevent="submit">
                <div class="form">
                  <div id="email-field" class="field-wrapper input">
                    <label for="email">EMAIL</label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-user"
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                      <polyline points="22,6 12,13 2,6" />
                    </svg>
                    <input id="email" v-model="cred.email" type="email" class="form-control" placeholder="Email">
                  </div>

                  <div id="password-field" class="field-wrapper input mb-2">
                    <div class="d-flex justify-content-between">
                      <label for="password">ПАРОЛЬ</label>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-lock"
                    >
                      <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
                      <path d="M7 11V7a5 5 0 0 1 10 0v4" />
                    </svg>
                    <input id="password" v-model="cred.password" :type="pwd_type" class="form-control" placeholder="Пароль">
                    <svg
                      id="toggle-password"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-eye"
                      @click="set_pwd_type"
                    >
                      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                      <circle cx="12" cy="12" r="3" />
                    </svg>
                  </div>
                  <div class="d-sm-flex justify-content-between">
                    <div class="field-wrapper">
                      <button
                        type="primary"
                        htmlType="submit"
                        class="btn btn-primary"
                        :disabled="!cred.email || !cred.password"
                      >
                        Войти
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, reactive } from 'vue'
import '@/assets/sass/authentication/auth-boxed.scss';

import { useRouter } from 'vue-router'
import { routesEnum } from '../../router/routes'
import { userService } from '../../services/user'

import { useMeta } from '@/composables/use-meta';

export default {
	name: 'Login',
	setup() {
		useMeta({ title: 'Авторизация' });

		const router = useRouter();
		const cred = reactive({
			email: '',
			password: '',
		})
		const pwd_type = ref('password');

		const set_pwd_type = () => {
			if (pwd_type.value === 'password') {
				pwd_type.value = 'text';
			} else {
				pwd_type.value = 'password';
			}
		};

		const submit = async () => {
			if(!cred.email || !cred.password) {
				notification.error({
					message: 'Required field not valid',
				})
				return false;
			}

			let success = await userService.login(cred);
			if (success) {
				router.push(routesEnum.home.path);
			}
		}

		return {
			cred,
			pwd_type,
			set_pwd_type,
			submit
		}
	}
}
</script>
