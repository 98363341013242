<template>
  <!-- BEGIN FOOTER -->
  <div class="footer-wrapper">
    <div class="footer-section f-section-1">
      <p class="">
        Standart Express © {{ (new Date()).getFullYear() }}
      </p>
    </div>
  </div>
  <!-- END FOOTER -->
</template>
