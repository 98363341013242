<template>
  <div class="layout-px-spacing">
    <teleport to="#breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="routesEnum.deals.path">
                    Сделки
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <span>Информация о сделке</span>
                </li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
    </teleport>

    <div class="row invoice layout-top-spacing layout-spacing apps-invoice">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <a-spin v-if="!orderDataLoaded" size="large" />

        <div v-else class="doc-container">
          <div class="row">
            <div class="col-xl-9">
              <div class="invoice-content">
                <div v-if="orderData.call" class="invoice-detail-body mt-0 mb-3">
                  <div class="invoice-detail-header">
                    <h5>Звонок: </h5>С номера "{{ orderData.call?.phone || '' }}" на номер "{{ orderData.call?.subscriber?.phone || '' }}"
                  </div>
                </div>
                <div class="invoice-inbox">
                  <div id="ct" class="">
                    <div class="invoice-00001">
                      <div class="content-section">
                        <div class="inv--head-section inv--detail-section">
                          <div class="row">
                            <div class="col-sm-6 col-12 me-auto">
                              <p class="inv-list-number">
                                <span class="inv-title">Заказчик : </span> <span>{{ orderData?.customer?.name || "-" }}</span>
                              </p>
                            </div>

                            <div class="col-sm-6 text-sm-end">
                              <p class="inv-list-number">
                                <span class="inv-title">Номер : </span> <span class="inv-title">#{{ orderData.id }}</span>
                              </p>
                            </div>

                            <div class="col-sm-6 align-self-center mt-3">
                              <p class="inv-street-addr">
                                <span class="inv-title">Наше юр.лицо : </span> <span>{{ orderData?.agent?.name || "-" }}</span>
                              </p>
                              <p class="inv-email-address">
                                <span class="inv-title">Менеджер ОП : </span> <span>{{ orderData?.creator?.fullName || "-" }}</span>
                              </p>
                              <p class="inv-email-address">
                                <span class="inv-title">Менеджер КС : </span> <span>{{ orderData?.manager?.fullName || "-" }}</span>
                              </p>
                            </div>
                            <div class="col-sm-6 align-self-center mt-3 text-sm-end">
                              <p class="inv-due-date">
                                <span class="inv-title">Дата заказа : </span> <span>{{ orderData.createdDate }}</span>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="inv--detail-section inv--customer-detail-section">
                          <div class="row">
                            <div class="col-md-12 align-self-center">
                              <p class="inv-to">
                                Адрес заявки
                              </p>
                            </div>

                            <div class="col-md-12">
                              <p class="inv-street-addr">
                                {{ orderData.addressWithoutIndex }}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="inv--product-table-section">
                          <div class="table-responsive">
                            <table class="table table-hover">
                              <thead>
                                <tr>
                                  <th v-for="item in columns" :key="item.key" :class="[item.class]">
                                    {{ item.label }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <template v-for="item in orderData.opServices" :key="item.id">
                                  <tr v-if="orderData.opServices.length !== 0">
                                    <td>
                                      {{ item.serviceType.name }}
                                    </td>
                                    <td>
                                      {{ item.customerOfferPerformers }}
                                    </td>
                                    <td>
                                      {{ item.count }}/{{ item.unit.name }}
                                    </td>
                                    <td class="text-end">
                                      {{ item.customerOfferBet }}
                                    </td>
                                    <td class="text-end">
                                      {{ item.extra || "0.00" }}
                                    </td>
                                    <td class="text-end">
                                      {{ item.total + ".00" }}
                                    </td>
                                    <td class="text-end">
                                      {{ item.executorOfferBet }}
                                    </td>
                                  </tr>
                                  <tr v-else>
                                    <td colspan="7">
                                      Услуг не найдено
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="inv--note">
                          <div class="row mt-4">
                            <div class="col-sm-12 col-12 order-sm-0 order-1">
                              <p>Комментарий: {{ orderData.comment || "-" }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="invoice-actions-btn mt-0">
                <div class="invoice-action-btn">
                  <div class="row">
                    <div class="col-xl-12 col-md-3 col-sm-6">
                      <router-link :to="routesEnum.dealEdit.path.replace(':id', id)" class="btn btn-dark btn-edit">
                        Редактировать
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import '@/assets/sass/apps/invoice-preview.scss';
import { useRoute } from 'vue-router'
import { useMeta } from '@/composables/use-meta';
import * as api from '@/services/api';
import { routesEnum } from '@/router/routes'

export default {
	name: 'DealPreview',
	setup() {
		useMeta({ title: 'Информация о сделке' });

		const route = useRoute();
		const id = ref(route.params.id);
		const orderDataLoaded = ref(false);
		const orderData = ref({});

		const columns = [
			{ key: 'serviceType', label: 'Тип услуги' },
			{ key: 'customerOfferPerformers', label: 'Кол-во людей' },
			{ key: 'serviceCountUnit', label: 'Кол-во единиц/Ед. измерения' },
			{ key: 'customerOfferBet', label: 'Ставка' },
			{ key: 'extra', label: 'Доп. расходы' },
			{ key: 'total', label: 'Итого' },
			{ key: 'executorOfferBet', label: 'Оплата исполнителю' },
		];

		onMounted(async () => {
			try {
				let params = {};
				params['expand'] = 'opServices.unit,opServices.serviceType,agent,customer,creator,manager,call';
				let { data } = await api.getDetailOrder(id.value, params)
				orderData.value = data;
				orderDataLoaded.value = true;
			} catch (e) {
				console.log(e);
			}
		})

		return {
			id,
			orderDataLoaded,
			orderData,
			columns,
			routesEnum,
		}
	}
}
</script>
