import { createRouter, createWebHistory } from 'vue-router';
import routes, { routesEnum } from './routes';
import store from '@/store'
import { getAccessToken, getRefreshToken } from '@/services/local-storage';

export const router = createRouter({
	history: createWebHistory(),
	routes,
})

// const sleep = ms => new Promise(res => setTimeout(res,ms))

const initialUserCheck = async () => {
	if (getAccessToken() && getRefreshToken()) {
		store.commit('setUser', true);
	}
	store.commit('setInitialCheckIsDone');
}

const isLoggedIn = async () => {
	if (!store.initialCheckIsDone) {
		await initialUserCheck()
	}
	if (!store.getters.isAuth) {
		return false
	}
	return true;
}

router.beforeEach(async (to) => {
	const loggedIn = await isLoggedIn(to)
	store.commit('setLayout', 'app');
	if (to.name === routesEnum.login.name) {
		if (loggedIn) {
			return routesEnum.home.path
		}
		store.commit('setLayout', 'auth');
	} else {
		if (!loggedIn) {
			store.commit('setLayout', 'auth');
			return routesEnum.login.path
		}
	}
})

export default router
