import { http } from './http'

export const login = async (loginData) => {
	return await http.post('auth/saler/token/', loginData)
}

export const refreshToken = async (refreshObj) => {
	return await http.post('auth/token/refresh/', refreshObj)
}

export const geUserData = async () => {
	return await http.post('users/profile/');
}

// Agent
export const getAgents = async (params) => {
	return await http.get('agents/', { params })
}

export const createAgent = async (data) => {
	return await http.post('agents/', data)
}

export const editAgent = async (id, data) => {
	return await http.put(`agents/${id}/`, data)
}

export const getDetailAgent = async (id, params) => {
	return await http.get(`agents/${id}/`, { params })
}

// Order
export const getOrders = async (params) => {
	return await http.get('op_orders/', { params })
}

export const createOrder = async (data) => {
	return await http.post('op_orders/', data)
}

export const editOrder = async (id, data) => {
	return await http.put(`op_orders/${id}/`, data)
}

export const patchOrder = async (id, data) => {
	return await http.patch(`op_orders/${id}/`, data)
}

export const getDetailOrder = async (id, params) => {
	return await http.get(`op_orders/${id}/`, { params })
}

export const setOrderStatus = async (id, data) => {
	return await http.post(`op_orders/${id}/set_status/`, data)
}

export const sendOrderToWork = async (id) => {
	return await http.post(`op_orders/${id}/send_to_work/`)
}

// Order file
export const getFiles = async (params) => {
	return await http.get('op_order_files/', { params })
}

export const createFile = async (data) => {
	return await http.post('op_order_files/', data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

export const editFile = async (id, data) => {
	return await http.put(`op_order_files/${id}/`, data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

export const deleteFile = async (id) => {
	return await http.delete(`op_order_files/${id}/`)
}

// Agent file
export const getAgentFiles = async (params) => {
	return await http.get('agent_files/', { params })
}

export const createAgentFile = async (data) => {
	return await http.post('agent_files/', data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

export const editAgentFile = async (id, data) => {
	return await http.put(`agent_files/${id}/`, data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

export const deleteAgentFile = async (id) => {
	return await http.delete(`agent_files/${id}/`)
}

// Service
export const getServices = async (params) => {
	return await http.get('op_order_services/', { params })
}

export const createService = async (data) => {
	return await http.post('op_order_services/', data)
}

export const editService = async (id, data) => {
	return await http.put(`op_order_services/${id}/`, data)
}

export const deleteService = async (id) => {
	return await http.delete(`op_order_services/${id}/`)
}

// Service type
export const getServiceTypes = async (params) => {
	return await http.get('service_types/', { params })
}

// Service unit
export const getServiceUnits = async (params) => {
	return await http.get('service_units/', { params })
}

// User
export const getUsers = async (params) => {
	return await http.get('users/', { params })
}

// Conatact person
export const getContactPersons = async (params) => {
	return await http.get('contact_persons/', { params })
}

export const createContactPerson = async (data) => {
	return await http.post('contact_persons/', data)
}

export const editContactPerson = async (id, data) => {
	return await http.put(`contact_persons/${id}/`, data)
}

export const deleteContactPerson = async (id) => {
	return await http.delete(`contact_persons/${id}/`)
}

// Call
export const moderateCall = async (id) => {
	return await http.post(`calls/${id}/moderate/`)
}

// Validation

export const checkPhoneNumber = async (data) => {
	return await http.post('validators/check_phone_number/', data)
}
export const checkEmail = async (data) => {
	return await http.post('validators/check_email/', data)
}

// Dashboard
export const getDashboardApplications = async (params) => {
	return await http.get('op_dashboard/applications/', { params })
}
export const getDashboardByTime = async (params) => {
	return await http.get('op_dashboard/by_time/', { params })
}
export const getDashboardBySource = async (params) => {
	return await http.get('op_dashboard/by_sources/', { params })
}
export const getDashboardCalls = async (params) => {
	return await http.get('op_dashboard/calls/', { params })
}
export const getDashboardEarnings = async (params) => {
	return await http.get('op_dashboard/earnings/', { params })
}
export const getDashboardNetProfit = async (params) => {
	return await http.get('op_dashboard/net_profit/', { params })
}
